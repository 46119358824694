/* eslint-disable unicorn/prefer-module */
import { useEffect, useState, useMemo } from "react";
import { Container, Loader } from "../../components";
import { useAuthContext } from "../../context/auth";
import { getEdificiosOperativos } from "../../utils";
import "./styles.scss";

function Inventario() {
  const [edificios, setEdificios] = useState();

  const { pais } = useAuthContext();

  useEffect(() => {
    const initialize = async () => {
      const newEdificios = await getEdificiosOperativos({ pais });
      setEdificios(newEdificios);
    };

    initialize();
  }, [pais]);

  const sortedEdificios = useMemo(() => {
    if (!edificios) return [];
    return [...edificios].sort((a, b) => a.edificio.localeCompare(b.edificio));
  }, [edificios]);

  const calculateEmpresasCantidad = (empresaslistado) => {
    if (!empresaslistado) {
      return 0;
    }

    if (empresaslistado.toUpperCase() === "NO DISPONIBLE") {
      return 0;
    }

    const empresasArray = empresaslistado.split(",");
    return empresasArray.length;
  };

  const totalEmpresasCantidad = useMemo(() => {
    if (edificios && edificios.length > 0) {
      return edificios.reduce((total, edificio) => total + calculateEmpresasCantidad(edificio.empresaslistado), 0);
    }
    return 0;
  }, [edificios]);

  return (
    <Container className="Admin" header titulo="EMPRESAS POR EDIFICIO">
      <div>
        {edificios ? (
          <div className="table-responsive mx-auto" style={{ width: "80vw" }}>
            <table className="table table-striped table-hover table-bordered table-sm">
              <thead className="table-dark align-middle">
                <tr>
                  <th scope="col" className="col-2">
                    EDIFICIO
                  </th>
                  <th scope="col" className="col-1 text-center">
                    CANTIDAD
                  </th>
                  <th scope="col" className="col text-center">
                    EMPRESAS
                  </th>
                </tr>
              </thead>
              <tbody className="table-striped align-middle">
                {sortedEdificios.map(({ id, edificio, empresaslistado }) => (
                  <tr key={id}>
                    <td className="col-2 text-left">{edificio.toUpperCase()}</td>
                    <td className="col-1 text-center">{calculateEmpresasCantidad(empresaslistado)}</td>
                    <td className="col text-center">{empresaslistado ? empresaslistado.toUpperCase() : ""}</td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr className="table-danger text-center align-middle">
                  {" "}
                  <td>TOTAL</td>
                  <td>{totalEmpresasCantidad}</td>
                  <td> </td>
                </tr>
              </tfoot>
            </table>
          </div>
        ) : (
          <Loader />
        )}
      </div>
    </Container>
  );
}

export default Inventario;
