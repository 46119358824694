import React, { useEffect } from "react";

function Redireccionamiento() {
  useEffect(() => {
    // Verifica si la URL actual contiene '/listadoB2B'
    if (window.location.href.includes("/listadoB2B")) {
      // Redirige a la nueva URL '/listadoempresas'
      window.location.href = window.location.href.replace("/listadoB2B", "/listadoempresas");
    }
  }, []); // Ejecutar solo una vez al montar el componente

  return <div>{/* Puedes agregar aquí cualquier contenido que desees mostrar en la página */}</div>;
}

export default Redireccionamiento;
