import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { FaFilePdf } from "react-icons/fa";
import {
  Container,
  CotizarCalendario,
  ListaDuracionPauta,
  ListaEdificios,
  ListaFormatos,
  ListaProductos,
  PdfCalendario,
  PeriodoPautaCalendario,
} from "../../components";
import { useAuthContext } from "../../context/auth";
import {
  calculateDailySpots,
  calculatePricePerSecond,
  getEdificiosOperativos,
  log,
  timeDiscountPercentage,
} from "../../utils";
import { PDFDownloadLink } from "@react-pdf/renderer";
import "./styles.scss";

export const CotizadorCalendario = () => {
  const [edificios, setEdificios] = useState();

  const { pais } = useAuthContext();

  const methods = useForm({
    defaultValues: {
      duracion: "0",
      formato: "0",
      periodoDePauta: "0",
      producto: "0",
    },
  });
  const { watch, handleSubmit, register, setValue } = methods;

  const [
    productoSelected,
    formatoSelected,
    duracionSelected,
    plazoSelected,
    pricePerSecond,
    dailySpots,
    screenAmount,
    peopleAmount,
    buildingAmount,
    inversionFria,
    discountInventoryPercentage,
    discountInventoryMoney,
    discountTimePercentage,
    discountTimeMoney,
    finalInvestment,
    periodoPautaInicio,
    periodoPautaFin,
  ] = watch([
    "producto",
    "formato",
    "duracion",
    "periodoDePauta",
    "pricePerSecond",
    "dailySpots",
    "pantallasTotales",
    "personasTotales",
    "edificiosTotales",
    "inversionFria",
    "discountInventoryPercentage",
    "discountInventoryMoney",
    "discountTimePercentage",
    "discountTimeMoney",
    "finalInvestment",
    "periodoPautaInicio",
    "periodoPautaFin",
  ]);

  const date = useMemo(() => {
    const today = new Date();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${today.getFullYear()}-${month}-${day}`;
  }, []);

  const pdfData = useMemo(
    () => ({
      buildingAmount,
      dailySpots,
      discountInventoryMoney,
      discountInventoryPercentage,
      discountTimeMoney,
      discountTimePercentage,
      duracionSelected,
      periodoPautaInicio,
      periodoPautaFin,
      finalInvestment,
      formatoSelected,
      inversionFria,
      peopleAmount,
      plazoSelected,
      pricePerSecond,
      productoSelected,
      screenAmount,
    }),
    [
      buildingAmount,
      dailySpots,
      discountInventoryMoney,
      discountInventoryPercentage,
      discountTimeMoney,
      discountTimePercentage,
      duracionSelected,
      periodoPautaInicio,
      periodoPautaFin,
      finalInvestment,
      formatoSelected,
      inversionFria,
      peopleAmount,
      plazoSelected,
      pricePerSecond,
      productoSelected,
      screenAmount,
    ]
  );

  const onSubmit = useCallback(
    async (data) => {
      log.debug("onSubmit", data);

      const isPorEdificio = data.producto === "POREDIFICIO";

      const newPricePerSecond = Number(
        calculatePricePerSecond({ producto: data.producto, formato: data.formato, pais }).toFixed(3)
      );
      setValue("pricePerSecond", newPricePerSecond);

      const newScreenAmount = isPorEdificio
        ? Number(data.pantallasTotales)
        : edificios.reduce((prev, curr) => prev + curr.pantallas, 0);
      !isPorEdificio && setValue("pantallasTotales", newScreenAmount);

      const newDailySpots = calculateDailySpots(data.formato, newScreenAmount);
      setValue("dailySpots", newDailySpots);

      const newPeopleAmount = isPorEdificio
        ? Number(data.personasTotales)
        : edificios.reduce((prev, curr) => prev + Number(curr.personas), 0);
      !isPorEdificio && setValue("personasTotales", newPeopleAmount);

      const newBuildingAmount = isPorEdificio ? Number(data.edificiosTotales) : edificios.length;
      !isPorEdificio && setValue("edificiosTotales", newBuildingAmount);

      const newDiscountInventoryPercentage = isPorEdificio
        ? 0.3 * (newScreenAmount / edificios.reduce((prev, curr) => prev + curr.pantallas, 0))
        : 0.3;
      setValue("discountInventoryPercentage", newDiscountInventoryPercentage);

      const newDiscountTimePercentage = timeDiscountPercentage(Number(data.periodoDePauta), data.formato);
      setValue("discountTimePercentage", newDiscountTimePercentage);

      const newInversionFria = newPricePerSecond * newDailySpots * Number(data.duracion) * Number(data.periodoDePauta);
      setValue("inversionFria", newInversionFria);

      const newDiscountTimeMoney = newInversionFria * newDiscountTimePercentage;
      setValue("discountTimeMoney", newDiscountTimeMoney);

      const newDiscountInventoryMoney = newInversionFria * newDiscountInventoryPercentage;
      setValue("discountInventoryMoney", newDiscountInventoryMoney);

      const newFinalInvestment = newInversionFria - newDiscountInventoryMoney - newDiscountTimeMoney;
      setValue("finalInvestment", newFinalInvestment);
    },
    [edificios, setValue, pais]
  );

  const forceSubmit = useCallback(() => handleSubmit(onSubmit)(), [handleSubmit, onSubmit]);

  const showPdfBtn = useMemo(
    () =>
      Number(pricePerSecond) > 0 &&
      Number(buildingAmount) > 0 &&
      finalInvestment !== undefined &&
      finalInvestment !== "",
    [pricePerSecond, buildingAmount, finalInvestment]
  );

  useEffect(() => {
    const initilize = async () => {
      const newEdificios = await getEdificiosOperativos({ pais });
      setEdificios(newEdificios);
    };

    initilize();
  }, [pais]);

  useEffect(() => {
    if (productoSelected !== "0" && formatoSelected !== "0" && duracionSelected !== "0" && plazoSelected !== "0")
      forceSubmit();
  }, [forceSubmit, productoSelected, formatoSelected, duracionSelected, plazoSelected]);

  return (
    <Container className="Cotizador" header titulo="COTIZADOR POR CALENDARIO">
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row mb-3">
            <ListaProductos className="col-3" />
            <ListaFormatos className="col-3" />
            <ListaDuracionPauta className="col-3" />
            <PeriodoPautaCalendario className="col-3" />
          </div>

          {productoSelected === "POREDIFICIO" && <ListaEdificios forceSubmit={forceSubmit} />}

          {Number(pricePerSecond) > 0 && Number(buildingAmount) > 0 && <CotizarCalendario className="col-12" />}

          <input hidden type="text" {...register("dailySpots")} />
          <input hidden type="text" {...register("discountInventoryMoney")} />
          <input hidden type="text" {...register("discountInventoryPercentage")} />
          <input hidden type="text" {...register("discountTimeMoney")} />
          <input hidden type="text" {...register("discountTimePercentage")} />
          <input hidden type="text" {...register("edificiosTotales")} />
          <input hidden type="text" {...register("finalInvestment")} />
          <input hidden type="text" {...register("inversionFria")} />
          <input hidden type="text" {...register("pantallasTotales")} />
          <input hidden type="text" {...register("periodoPautaFin")} />
          <input hidden type="text" {...register("periodoPautaInicio")} />
          <input hidden type="text" {...register("personasTotales")} />
          <input hidden type="text" {...register("pricePerSecond")} />
        </form>

        {showPdfBtn && (
          <PDFDownloadLink
            className="btn btn-success btn-pdf"
            document={<PdfCalendario data={pdfData} />}
            fileName={`${date} - Cotización Visiona`}
          >
            Generar PDF - <FaFilePdf />
          </PDFDownloadLink>
        )}
      </FormProvider>
    </Container>
  );
};
