/* eslint-disable no-console */
import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from "reactstrap";
import cx from "classix";
import { BlackArrow, Container, Loader } from "../../components";
import { useAuthContext } from "../../context/auth";
import { getCategorias, getIntermediarios, log, quitarCaracteresEspeciales, saveCliente } from "../../utils";

export const CargarCliente = () => {
  const [categorias, setCategorias] = useState();
  const [intermediarios, setIntermediarios] = useState();
  const [showModalConfirmacion, setShowModalConfirmacion] = useState(false);
  const [temporalDataForm, setTemporalDataForm] = useState();

  const { pais } = useAuthContext();

  const {
    formState: { errors },
    handleSubmit,
    register,
    reset,
    setError,
  } = useForm();

  const onSubmit = useCallback(
    async (data) => {
      setShowModalConfirmacion(true);

      const newCliente = {
        nombre: quitarCaracteresEspeciales(data.nombre),
        agencia: data.agencia.toUpperCase(),
        categoria: data.categoria.toUpperCase(),
        lastupdate: new Date(),
        pais,
        // direccion: data.direccion.toUpperCase(),
        // razonsocial: data.razonsocial.toUpperCase(),
        // rut: data.rut,
      };

      setTemporalDataForm(newCliente);
    },
    [pais]
  );

  const saveButtonHandle = useCallback(async () => {
    try {
      setShowModalConfirmacion(false);
      await saveCliente(temporalDataForm);
      toast.success("El cliente se guardó correctamente");
      reset();
    } catch (error) {
      if (error.code === "23505") setError("nombre", { type: "custom", message: "El cliente ya existe" });
      toast.error("Hubo un error al intentar guardar el nuevo cliente, prueba de nuevo.");
      log.error("saveButtonHandle", error);
    }
  }, [temporalDataForm, reset, setError]);

  const validarSelect = useCallback((value) => value !== "0", []);

  const disableSubmit = useMemo(() => showModalConfirmacion, [showModalConfirmacion]);

  useEffect(() => {
    const initialize = async () => {
      const newIntermediarios = await getIntermediarios({ pais });
      setIntermediarios(newIntermediarios);

      const newCategorias = await getCategorias();
      setCategorias(newCategorias);
    };

    initialize();
  }, [pais]);

  if (intermediarios === undefined || categorias === undefined) return <Loader />;

  console.log("intermediarios", intermediarios);
  console.log("pais", pais);

  return (
    <>
      <BlackArrow />
      <Container className="CargarCliente" header titulo="CARGAR NUEVO CLIENTE">
        <form className="row g-3" onSubmit={handleSubmit(onSubmit)}>
          <div className="col-4">
            <label htmlFor="nombre" className="form-label">
              Nombre comercial
            </label>
            <input
              className={cx("form-control", errors?.nombre && "is-invalid")}
              id="nombre"
              placeholder="Ej.: LATAM AIRLINES, 25 caracteres máx."
              type="text"
              maxLength={25}
              {...register("nombre", { required: "Este campo es obligatorio" })}
            />

            <div className="invalid-feedback">{errors?.nombre?.message}</div>
          </div>

          <div className="col-4">
            <label htmlFor="agencia" className="form-label">
              Categoría
            </label>
            <select
              className={cx("form-select", errors?.categoria && "is-invalid")}
              id="categoria"
              defaultValue="0"
              {...register("categoria", { validate: validarSelect })}
            >
              <option disabled value="0">
                Selecciona una opción
              </option>

              {categorias.map(({ nombre, id }) => (
                <option key={id} value={nombre}>
                  {nombre}
                </option>
              ))}
            </select>

            <div className="invalid-feedback">Este campo es obligatorio</div>
          </div>

          <div className="col-4">
            <label htmlFor="agencia" className="form-label">
              Intermediario
            </label>
            <select
              className={cx("form-select", errors?.agencia && "is-invalid")}
              id="agencia"
              defaultValue="0"
              {...register("agencia")}
            >
              <option disabled value="0">
                Selecciona una opción
              </option>

              {intermediarios.map(({ nombre, id }) => (
                <option key={id} value={nombre}>
                  {nombre}
                </option>
              ))}
            </select>

            <div className="invalid-feedback">Este campo es obligatorio</div>
          </div>

          {/* <div className="col-6">
          <label htmlFor="razonsocial" className="form-label">
            Razón Social
          </label>
          <input
            className={cx("form-control", errors?.razonsocial && "is-invalid")}
            id="razonsocial"
            placeholder="Ej.: Compañía S.A."
            type="text"
            {...register("razonsocial", { required: true })}
          />

          <div className="invalid-feedback">Este campo es obligatorio</div>
        </div>

        <div className="col-6">
          <label htmlFor="rut" className="form-label">
            RUT
          </label>
          <input
            className={cx("form-control", errors?.rut && "is-invalid")}
            id="rut"
            placeholder="Ej.: 4564654646654"
            type="texto"
            {...register("rut", { required: true })}
          />

          <div className="invalid-feedback">Este campo es obligatorio</div>
        </div>

        <div className="col-6">
          <label htmlFor="direccion" className="form-label">
            Dirección
          </label>
          <input
            className={cx("form-control", errors?.direccion && "is-invalid")}
            id="direccion"
            placeholder="Ej.: Dirección 1234, esq. Calle"
            type="text"
            {...register("direccion", { required: true })}
          />

          <div className="invalid-feedback">Este campo es obligatorio</div>
        </div> */}

          <div className="col-12 text-center">
            <button className="btn btn-primary" type="submit" disabled={disableSubmit}>
              {!disableSubmit ? (
                "GUARDAR"
              ) : (
                <>
                  <Spinner size="sm">GUARDANDO...</Spinner>
                  <span> GUARDANDO</span>
                </>
              )}
            </button>
          </div>
        </form>

        <Modal isOpen={showModalConfirmacion} toggle={() => setShowModalConfirmacion(false)}>
          <ModalHeader>¿Está seguro?</ModalHeader>
          <ModalBody>Se está por guardar un nuevo cliente</ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => setShowModalConfirmacion(false)}>
              Cancelar
            </Button>
            <Button color="primary" onClick={saveButtonHandle}>
              Guardar
            </Button>
          </ModalFooter>
        </Modal>
      </Container>
    </>
  );
};
