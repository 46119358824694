import { log, supabase } from ".";

export const getCurrentUser = async () => {
  try {
    const user = await supabase.getCurrentUser();

    if (user) {
      const usuarios = await supabase.select("usuarios", {
        filters: { email: user.email },
      });

      if (usuarios?.length === 0) {
        toast.error("Error con el usuario");
        throw new Error("No user role");
      }

      const newUser = { ...user, permissions: { ...usuarios[0] } };

      return newUser;
    } else throw new Error("not logged");
  } catch (error) {
    log.error("getCurrentUser", error);
    throw error;
  }
};
