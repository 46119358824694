import { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import cx from "classix";
import Ayuda from "../Ayuda";

export const ListaDuracionPauta = ({ className }) => {
  const { register } = useFormContext();

  const cantidadSegundos = useMemo(() => Array.from({ length: 10 }, (_, i) => i + 6), []);

  return (
    <div className={cx("duracion", className)} style={{ position: "relative" }}>
      <div className="form-floating">
        <select className="form-select" {...register("duracion")}>
          <option disabled value="0">
            Selecciona una opción
          </option>
          {cantidadSegundos.map((sec) => (
            <option key={sec}>{sec}</option>
          ))}
        </select>
        <label>3. Duración de la publicidad</label>
      </div>
      <div style={{ position: "absolute", top: "0", right: "0" }}>
        <Ayuda
          textoAyuda={[
            "MÍNIMO: 6 SEGUNDOS",
            "MÁXIMO: 15 SEGUNDOS",
            "MAYOR DURACIÓN <=> MAYOR INVERSIÓN",
            "MENOR DURACIÓN <=> MENOR INVERSIÓN",
          ]}
        />
      </div>
    </div>
  );
};
