import { BsBuilding } from "react-icons/bs";
import { BsFileSpreadsheet } from "react-icons/bs";
import { CiUser } from "react-icons/ci";
import { GiChart } from "react-icons/gi";
import { GiSouthAmerica } from "react-icons/gi";
import { HiOutlineUsers } from "react-icons/hi2";
import { LuClipboardList } from "react-icons/lu";
import { MdDashboard } from "react-icons/md";
import { TfiMoney } from "react-icons/tfi";
import { Link } from "react-router-dom";
import { Container, Loader } from "../../components";
import { ROLES } from "../../constants/roles";
import { useAuthContext } from "../../context/auth";
import "./styles.scss";

export const Admin = () => {
  const { session, rol } = useAuthContext();

  if (session === undefined) return <Loader fullPage />;

  return (
    <Container className="Admin" header titulo="ADMINISTRACIÓN">
      <div className="botones row align-middle">
        {rol === ROLES.SUPERADMIN && (
          <>
            <div className="col-3">
              <Link to="/admin/ventas-dashboard" className="link">
                <MdDashboard className="icon" size={56} />
                <span>Dashboard</span>
              </Link>
            </div>
            <div className="col-3">
              <Link to="/admin/ventas-detalle" className="link">
                <TfiMoney className="icon" size={56} />
                <span>Ventas</span>
              </Link>
            </div>
            <div className="col-3">
              <Link to="/admin/ventas-proyeccion" className="link">
                <GiChart className="icon" size={56} />
                <span>Proyección Comercial</span>
              </Link>
            </div>
            <div className="col-3">
              <Link to="/admin/ventas-region" className="link">
                <GiSouthAmerica className="icon" size={56} />
                <span>Región</span>
              </Link>
            </div>
            <div className="col-3">
              <Link to="/admin/online-cotizador" className="link">
                <BsFileSpreadsheet className="icon" size={56} />
                <span>Cotizador</span>
              </Link>
            </div>
            <div className="col-3">
              <Link to="/admin/cargar-intermediario" className="link">
                <LuClipboardList className="icon" size={56} />
                <span>Cargar Agencia</span>
              </Link>
            </div>
            <div className="col-3">
              <Link to="/admin/cargar-cliente" className="link">
                <LuClipboardList className="icon" size={56} />
                <span>Cargar Cliente</span>
              </Link>
            </div>
            <div className="col-3">
              <Link to="/admin/cargar-oportunidad" className="link">
                <LuClipboardList className="icon" size={56} />
                <span>Cargar Oportunidad</span>
              </Link>
            </div>
            {/* <div className="col-3">
              <Link to="/admin/cargar-factura" className="link">
                <LuClipboardList className="icon" size={56} />
                <span>Cargar Factura</span>
              </Link>
            </div> */}
            <div className="col-3">
              <Link to="/admin/ventas-facturas" className="link">
                <BsBuilding className="icon" size={56} />
                <span>Facturación</span>
              </Link>
            </div>
            <div className="col-3">
              <Link to="/admin/informes-comision" className="link">
                <LuClipboardList className="icon" size={56} />
                <span>Comisión Edificios</span>
              </Link>
            </div>
            <div className="col-3">
              <Link to="/admin/usuarios" className="link">
                <HiOutlineUsers className="icon" size={56} />
                <span>Usuarios</span>
              </Link>
            </div>
            <div className="col-3">
              <Link to="/perfil" className="link">
                <CiUser className="icon" size={56} />
                <span>Perfil</span>
              </Link>
            </div>
          </>
        )}
        {rol === ROLES.COMERCIAL && (
          <>
            <div className="col-3">
              <Link to="/admin/ventas-dashboard" className="link">
                <MdDashboard className="icon" size={56} />
                <span>Dashboard</span>
              </Link>
            </div>
            <div className="col-3">
              <Link to="/admin/ventas-detalle" className="link">
                <TfiMoney className="icon" size={56} />
                <span>Ventas</span>
              </Link>
            </div>
            <div className="col-3">
              <Link to="/admin/ventas-proyeccion" className="link">
                <GiChart className="icon" size={56} />
                <span>Proyección Comercial</span>
              </Link>
            </div>
            <div className="col-3">
              <Link to="/admin/ventas-region" className="link">
                <GiSouthAmerica className="icon" size={56} />
                <span>Región</span>
              </Link>
            </div>
            <div className="col-3">
              <Link to="/admin/online-cotizador" className="link">
                <BsFileSpreadsheet className="icon" size={56} />
                <span>Cotizador online</span>
              </Link>
            </div>
            <div className="col-3">
              <Link to="/admin/cargar-intermediario" className="link">
                <LuClipboardList className="icon" size={56} />
                <span>Cargar Agencia</span>
              </Link>
            </div>
            <div className="col-3">
              <Link to="/admin/cargar-cliente" className="link">
                <LuClipboardList className="icon" size={56} />
                <span>Cargar Cliente</span>
              </Link>
            </div>
            <div className="col-3">
              <Link to="/admin/cargar-oportunidad" className="link">
                <LuClipboardList className="icon" size={56} />
                <span>Cargar Oportunidad</span>
              </Link>
            </div>
            <div className="col-3">
              <Link to="/admin/informes-comision" className="link">
                <LuClipboardList className="icon" size={56} />
                <span>Comisión Edificios</span>
              </Link>
            </div>
          </>
        )}
        {rol === ROLES.ADMINISTRATIVO && (
          <>
            {/* <div className="col-3">
              <Link to="/admin/cargar-factura" className="link">
                <LuClipboardList className="icon" size={56} />
                <span>Cargar Factura</span>
              </Link>
            </div> */}
            <div className="col-3">
              <Link to="/admin/ventas-facturas" className="link">
                <BsBuilding className="icon" size={56} />
                <span>Facturación</span>
              </Link>
            </div>
            <div className="col-3">
              <Link to="/admin/informes-comision" className="link">
                <LuClipboardList className="icon" size={56} />
                <span>Comisión Edificios</span>
              </Link>
            </div>
            <div className="col-3">
              <Link to="/admin/ventas-dashboard" className="link">
                <MdDashboard className="icon" size={56} />
                <span>Dashboard</span>
              </Link>
            </div>
            <div className="col-3">
              <Link to="/admin/ventas-detalle" className="link">
                <TfiMoney className="icon" size={56} />
                <span>Ventas</span>
              </Link>
            </div>
          </>
        )}
      </div>
    </Container>
  );
};
