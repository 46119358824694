import { useState } from "react";
import { log, supabase } from "../../utils";

function UsuarioList({ id, correo, nombre, apellido, tipo }) {
  const [isEditable, setIsEditable] = useState(false);

  const actualizarTipo = async (e, id) => {
    const nuevoTipo = e.target.value;
    try {
      const { data, error, status } = await supabase.from("usuarios").update({ tipo: nuevoTipo }).match({ id: id });
      if (error && status !== 406) {
        throw error;
      }
      if (data) {
        // Blank
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("actualizarTipo", error);
    }
  };

  const toggleEdicion = (e, id) => {
    const nuevoValor = e.target.checked;
    setIsEditable(nuevoValor);
  };

  const guardarCambios = async (e) => {
    e.preventDefault();
    try {
      // incorporar un IF para validar que los valores no estén vacíos/null

      const nuevaData = {
        nombre: e.target.nombre.value,
        apellido: e.target.apellido.value,
        correo: e.target.correo.value,
        tipo: e.target.tipo.value,
      };
      const { data, error, status } = await supabase.from("usuarios").update(nuevaData).match({ id: id });
      log.debug("nueva data", nuevaData);
      if (error && status !== 406) {
        throw error;
      }
      if (data) {
        log.debug("data", data);
        setIsEditable(false);
        nombre = e.target.nombre.value;
      }
    } catch (error) {
      log.error("actualizarTipo", error);
    }
  };
  return (
    <div>
      <li key={id}>
        <div className="usuario-list-item">
          {!isEditable ? (
            <>
              <div>{tipo}</div>
              <div>{nombre}</div>
              <div>{apellido}</div>
              <div>{correo}</div>
            </>
          ) : (
            <form onSubmit={guardarCambios} className="usuario-list-item-form">
              <div>
                <input className="input" name="nombre" type="text" defaultValue={nombre} />
              </div>
              <div>
                <input className="input" name="apellido" type="text" defaultValue={apellido} />
              </div>
              <div>
                <input className="input" name="correo" type="mail" defaultValue={correo} />
              </div>
              <div>
                <select
                  defaultValue={tipo === null ? "sin-asingar" : tipo}
                  onChange={(e) => actualizarTipo(e, id)}
                  name="tipo"
                  placeholder="Seleccionar...!"
                >
                  <option value="sin-asignar">Seleccionar...</option>
                  <option value="admin">Admin</option>
                  <option value="edificio">Edificio</option>
                  <option value="cliente">Cliente</option>
                  <option value="socio">Socio</option>
                </select>
              </div>
              <div>
                <input type="submit" value="Guardar" />
              </div>
            </form>
          )}
          <div>
            {!isEditable ? (
              <input type="checkbox" style={{ color: "black" }} onChange={(e) => toggleEdicion(e, id)} />
            ) : (
              <></>
            )}
          </div>
        </div>
      </li>
    </div>
  );
}

export default UsuarioList;
