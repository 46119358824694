import { Container, GraficasVentas, GraficasCategorias } from "../../components";

const VentasDashboard = () => (
  <Container header titulo="DASHBOARD COMERCIAL">
    <p style={{ margin: "0 auto", textAlign: "center" }}>IMPORTES EN MILES</p>
    <div style={{ marginBottom: "2rem" }}>
      <GraficasVentas />
    </div>
    <div>
      <GraficasCategorias />
    </div>
  </Container>
);

export default VentasDashboard;
