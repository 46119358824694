import { BlackArrow, Container } from "../../components";
import "./styles.scss";

const VentasRegion = () => (
  <div>
    <BlackArrow />
    <Container className="Admin" header titulo="DASHBOARD REGIONAL">
      <p>En construcción</p>
    </Container>
  </div>
);

export default VentasRegion;
