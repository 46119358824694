import { useCallback } from "react";
import { useFormContext } from "react-hook-form";
import cx from "classix";
import { useAuthContext } from "../../context/auth";
import "./styles.scss";

export const CotizarCalendario = ({ className }) => {
  const { pais } = useAuthContext();

  const { watch } = useFormContext();

  const [
    duracionSelected,
    plazoSelected,
    pricePerSecond,
    dailySpots,
    screenAmount,
    peopleAmount,
    buildingAmount,
    inversionFria,
    discountInventoryPercentage,
    discountInventoryMoney,
    discountTimePercentage,
    discountTimeMoney,
    finalInvestment,
    periodoPautaInicio,
    periodoPautaFin,
  ] = watch([
    "duracion",
    "periodoDePauta",
    "pricePerSecond",
    "dailySpots",
    "pantallasTotales",
    "personasTotales",
    "edificiosTotales",
    "inversionFria",
    "discountInventoryPercentage",
    "discountInventoryMoney",
    "discountTimePercentage",
    "discountTimeMoney",
    "finalInvestment",
    "periodoPautaInicio",
    "periodoPautaFin",
  ]);

  const localString = useCallback(
    (pais) =>
      ({
        Chile: "es-CL",
        Perú: "es-PE",
        Uruguay: "es-UY",
      })[pais] || "es-UY",
    []
  );

  const moneda = useCallback(
    (pais) =>
      ({
        Chile: "$",
        Perú: "S/",
        Uruguay: "$",
      })[pais] || "$",
    []
  );

  const monedaTextual = useCallback(
    (pais) =>
      ({
        Chile: "PESOS CHILENOS",
        Perú: "SOLES PERUANOS",
        Uruguay: "PESOS URUGUAYOS",
      })[pais] || "PESOS URUGUAYOS",
    []
  );

  return (
    <div className={cx("Cotizar", className)}>
      {pricePerSecond > 0 && (
        <>
          <div className="cotizacion">
            <div className="left">Cantidad de edificios:</div>
            <div className="right">{buildingAmount}</div>
            <div className="left">Cantidad de pantallas:</div>
            <div className="right">{screenAmount}</div>
            <div className="left">Cantidad de personas:</div>
            <div className="right">
              {peopleAmount.toLocaleString(localString(pais), {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </div>
            <div className="left">Duración pieza publicitaria:</div>
            <div className="right">{duracionSelected} segundos</div>
            <div className="left">Período de pauta:</div>
            <div className="right">
              {periodoPautaInicio} al {periodoPautaFin}
            </div>
            <div className="left">Duración de la pauta:</div>
            <div className="right">
              <span className="cursiva" />
              {plazoSelected} días
            </div>
            <div className="left">Cantidad de spots por día:</div>
            <div className="right">
              {dailySpots.toLocaleString(localString(pais), {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </div>
            <div className="left">Precio del segundo:</div>
            <div className="right">{`${moneda(pais)} ${pricePerSecond.toLocaleString(localString(pais), {
              minimumFractionDigits: 3,
              maximumFractionDigits: 3,
            })}`}</div>
            <div className="left">Inversión (tarifa fría):</div>
            <div className="right">
              <span className="cursiva" />
              {`${moneda(pais)} ${inversionFria.toLocaleString(localString(pais), {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}`}
            </div>
            <div className="left">Descuento por inventario:</div>
            <div className="right">
              <span>{`${(discountInventoryPercentage * 100).toFixed(1)}%`}</span> {"---->"}{" "}
              <span className="descuento">
                {`${moneda(pais)} ${discountInventoryMoney.toLocaleString(localString(pais), {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}`}
              </span>
            </div>
            <div className="left">Descuento por tiempo de compra:</div>
            <div className="right">
              <span> {(discountTimePercentage * 100).toFixed(1)}% </span>
              {"---->"}{" "}
              <span className="descuento">
                {`${moneda(pais)} ${discountTimeMoney.toLocaleString(localString(pais), {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}`}
              </span>
            </div>
            <div className="left">Inversión final:</div>
            <div className="right">
              {`${moneda(pais)} ${finalInvestment.toLocaleString(localString(pais), {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}`}
            </div>
          </div>
          <div>
            <p className="letraChiquita">
              Propuesta exclusiva para agencia y/o cliente cotizado. Importes expresados en {monedaTextual(pais)} y no
              incluyen impuestos. Salidas según formato contratado y de lunes a viernes de 7 AM a 9 PM. Propuesta
              incluye descuentos especiales que no podrán considerarse como antecedente. El cliente podrá cambiar
              materiales tantas veces como desee. Cotización válida para pautas a emitirse hasta diciembre 2024
              inclusive.
            </p>
          </div>
        </>
      )}
    </div>
  );
};
