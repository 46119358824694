/* eslint-disable unicorn/prefer-module */
/* eslint-disable no-console */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Loader } from "..";
import { FaLocationDot } from "react-icons/fa6";
import { IoPeople } from "react-icons/io5";
import { MdOutlineBusinessCenter } from "react-icons/md";
import { MdElevator } from "react-icons/md";
import { TiStar } from "react-icons/ti";
import { useAuthContext } from "../../context/auth";
import { getEdificiosOperativos } from "../../utils";
import "./styles.scss";

export const FichaEdificios = () => {
  const [edificios, setEdificios] = useState();

  const { pais } = useAuthContext();

  const localString = useCallback(
    (pais) =>
      ({
        Chile: "es-CL",
        Perú: "es-PE",
        Uruguay: "es-UY",
      })[pais] || "es-UY",
    []
  );

  const logo = useMemo(
    () =>
      ({
        Chile: require("../../img/wecast-logo.png"),
        Perú: require("../../img/visiona-logo.png"),
        Uruguay: require("../../img/screenmedia-logo.png"),
      })[pais] || require("../../img/visiona-logo.png"),
    [pais]
  );

  useEffect(() => {
    const initialize = async () => {
      const newEdificios = await getEdificiosOperativos({ pais });
      newEdificios.sort((a, b) => b.personas - a.personas);
      setEdificios(newEdificios);
    };

    initialize();
  }, [pais]);

  const calculateEmpresasCantidad = (empresaslistado) => {
    if (!empresaslistado) {
      return 0;
    }

    if (empresaslistado.toUpperCase() === "NO DISPONIBLE") {
      return 0;
    }

    const empresasArray = empresaslistado.split(",");
    return empresasArray.length;
  };
  if (edificios === undefined) return <Loader />;

  return (
    <div>
      <header className="header">
        <img src={logo} alt="Logo" className="logo" />
        <h1 className="titulo">PORTAFOLIO</h1>
      </header>
      <div>
        {edificios.map((edificio) => (
          <div key={edificio.id} className="ficha-edificios">
            <div className="nombre-edificio">{edificio.edificio.toUpperCase()}</div>
            <FaLocationDot className="ubicacion-icono" />
            <div className="ubicacion-texto">{edificio.direccion}</div>
            <MdElevator className="ascensores-icono" />
            <div className="ascensores-texto">{edificio.pantallas} ascensores con pantallas FULLHD</div>
            <IoPeople className="personas-icono" />
            <div className="personas-texto">
              {edificio.personas.toLocaleString(localString(pais))} personas únicas diarias
            </div>
            <MdOutlineBusinessCenter className="empresas-icono" />
            <div className="empresas-texto">{calculateEmpresasCantidad(edificio.empresaslistado)} empresas</div>
            <img
              className="edificios-imagen-edificio"
              src={require(`../../img/edificios/${edificio.id}.png`)}
              alt={edificio.edificio}
            />
            <div className="recuadro-rojo">
              <TiStar className="empresas-destacadas-icono" />
              <div className="empresas-destacadas-texto">{edificio.empresasdestacadas}</div>
              <img
                className="edificios-imagen-ascensor"
                src={require(`../../img/edificios/${edificio.id}-ascensor.png`)}
                alt={edificio.edificio}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
