/* eslint-disable unicorn/prefer-module */
import React, { memo, useCallback, useMemo } from "react";
import { Document, Page, Text, View, Image } from "@react-pdf/renderer";

import styles from "./styles";

const today = new Date();
const month = String(today.getMonth() + 1).padStart(2, "0");
const day = String(today.getDate()).padStart(2, "0");
const date = `${day}-${month}-${today.getFullYear()}`;
const hour = String(today.getHours()).padStart(2, "0");
const minute = String(today.getMinutes()).padStart(2, "0");

const Pdf = ({ data, pais }) => {
  const ciudad = useCallback(
    (pais) =>
      ({
        Chile: "Santiago",
        Perú: "Lima",
        Uruguay: "Montevideo",
      })[pais] || "Lima",
    []
  );

  const operacionTextual = useCallback(
    (pais) =>
      ({
        Chile: "WECAST",
        Perú: "VISIONA",
        Uruguay: "SCREENMEDIA",
      })[pais] || "VISIONA",
    []
  );

  const localString = useCallback(
    (pais) =>
      ({
        Chile: "es-CL",
        Perú: "es-PE",
        Uruguay: "es-UY",
      })[pais] || "es-PE",
    []
  );

  const monedaSimbolo = useCallback(
    (pais) =>
      ({
        Chile: "$ ",
        Perú: "S/ ",
        Uruguay: "$ ",
      })[pais] || "S/ ",
    []
  );

  const monedaTextual = useCallback(
    (pais) =>
      ({
        Chile: "PESOS CHILENOS",
        Perú: "SOLES PERUANOS",
        Uruguay: "PESOS URUGUAYOS",
      })[pais] || "SOLES PERUANOS",
    []
  );

  const mailTextual = useCallback(
    (pais) =>
      ({
        Chile: "hola@wecast.cl",
        Perú: "hola@visiona.pe",
        Uruguay: "hola@screenmedia.com.uy",
      })[pais] || "hola@visiona.pe",
    []
  );

  const logo = useMemo(
    () =>
      ({
        Chile: require("../../img/wecast-logo.png"),
        Perú: require("../../img/visiona-logo.png"),
        Uruguay: require("../../img/screenmedia-logo.png"),
      })[pais] || require("../../img/visiona-logo.png"),
    [pais]
  );

  return (
    <Document>
      <Page style={styles.body} size="A4" orientation="landscape">
        <Text style={styles.header} fixed>
          ~ CONFIDENCIAL ~
        </Text>
        <Text style={styles.letrachica1}>
          {ciudad(pais)}, {date} @ {hour}:{minute}
        </Text>
        <Text style={styles.title}>COTIZACIÓN ESPACIOS PUBLICITARIOS en {operacionTextual(pais)}</Text>
        <View style={styles.row}>
          <Text style={styles.margin2}>Producto: </Text>
          <Text style={styles.margin3}>{data.productoSelected}</Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.margin2}>Edificios: </Text>
          <Text style={styles.margin3}>{data.buildingAmount}</Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.margin2}>Pantallas: </Text>
          <Text style={styles.margin3}>{data.screenAmount}</Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.margin2}>Alcance: </Text>
          <Text style={styles.margin3}>
            {`${data.peopleAmount.toLocaleString(localString(pais), {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })} personas`}
          </Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.margin2}>Duración pieza publicitaria: </Text>
          <Text style={styles.margin3}>{data.duracionSelected} segundos</Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.margin2}>Período de pauta: </Text>
          <Text style={styles.margin3}>
            {data.periodoPautaInicio} al {data.periodoPautaFin}
          </Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.margin2}>Duración de la pauta: </Text>
          <Text style={styles.margin3}>{data.plazoSelected} días</Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.margin2}>Cantidad de spots por día: </Text>
          <Text style={styles.margin3}>
            {data.dailySpots.toLocaleString(localString(pais), {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })}
          </Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.margin2}>Precio del segundo: </Text>
          <Text style={styles.margin3}>
            {monedaSimbolo(pais)}
            {data.pricePerSecond.toLocaleString(localString(pais), {
              minimumFractionDigits: 4,
              maximumFractionDigits: 4,
            })}
          </Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.margin2}>Formato de pauta: </Text>
          <Text style={styles.margin3}>{data.formatoSelected}</Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.margin2}>Inversión (tarifa fría): </Text>
          <Text style={styles.margin3}>
            {monedaSimbolo(pais)}
            {data.inversionFria.toLocaleString(localString(pais), {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })}
          </Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.margin2}>Descuento por inventario: </Text>
          <Text style={styles.margin4}>{`${(data.discountInventoryPercentage * 100).toFixed(1)}%`}</Text>
          <Text style={styles.margin5}>
            {`- ${monedaSimbolo(pais)} ${data.discountInventoryMoney.toLocaleString(localString(pais), {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })}`}
          </Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.margin2}>Descuento por tiempo de compra: </Text>
          <Text style={styles.margin4}>{`${(data.discountTimePercentage * 100).toFixed(1)}%`}</Text>
          <Text style={styles.margin5}>
            {`- ${monedaSimbolo(pais)} ${data.discountTimeMoney.toLocaleString(localString(pais), {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })}`}
          </Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.margin6}>Inversión final: </Text>
          <Text style={styles.margin7}>
            {monedaSimbolo(pais)}
            {data.finalInvestment.toLocaleString(localString(pais), {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })}
          </Text>
        </View>
        <View>
          <Text style={styles.letrachica2}>
            Propuesta exclusiva para agencia y/o cliente cotizado. Importes expresados en {monedaTextual(pais)} y no
            incluyen impuestos. Salidas según formato contratado y de lunes a viernes de 7 AM a 9 PM. Propuesta incluye
            descuentos especiales que no podrán considerarse como antecedente. El cliente podrá cambiar materiales
            tantas veces como desee. Cotización válida para pautas a emitirse hasta diciembre 2024 inclusive.
          </Text>
        </View>
        <Image style={styles.image} src={logo} />
        <Text style={styles.mail}>{mailTextual(pais)}</Text>
      </Page>
    </Document>
  );
};

export default memo(Pdf);
