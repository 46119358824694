import toast from "react-hot-toast";
import { log, supabase } from ".";

export const getUsers = async () => {
  try {
    const data = await supabase.select("usuarios", {
      orderBy: { id: "desc" },
    });

    if (data?.length === 0) {
      toast.error("No hay facturas cargadas en la base de datos");
      throw new Error("No data found");
    }

    log.debug("getUsers", data);
    return data;
  } catch (error) {
    log.error("getUsers", error);
  }
};
