/* eslint-disable unicorn/prefer-module */
import React, { memo, useMemo } from "react";
import { AiOutlineNumber } from "react-icons/ai";
import { BsFillCalendar2DateFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import { useAuthContext } from "../../context/auth";
import "./styles.scss";

export const Cotizador = memo(({ children, className, header = false, titulo = "titulo" }) => {
  const { pais } = useAuthContext();

  const logo = useMemo(
    () =>
      ({
        Chile: require("../../img/wecast-logo.png"),
        Perú: require("../../img/visiona-logo.png"),
        Uruguay: require("../../img/screenmedia-logo.png"),
      })[pais] || require("../../img/visiona-logo.png"),
    [pais]
  );

  return (
    <div>
      <img src={logo} alt="Logo" className="logo" />
      <p className="titulo">SELECCIONE COTIZADOR</p>
      <div className="botones">
        <div className="react-icons">
          <div className="alineacionvertical1">
            <Link to={"../admin/online-cotizador/dias"}>
              <AiOutlineNumber style={{ color: "DarkSlateGrey" }} size={75} />
            </Link>
          </div>
          <div className="alineacionvertical2">
            <p>CANTIDAD DE DÍAS</p>
          </div>
        </div>
        <div className="react-icons">
          <div className="alineacionvertical1">
            <Link to={"../admin/online-cotizador/calendario"}>
              <BsFillCalendar2DateFill style={{ color: "DarkSlateGrey" }} size={75} />
            </Link>
          </div>
          <div className="alineacionvertical2">
            <p>FECHAS EXACTAS</p>
          </div>
        </div>
      </div>
    </div>
  );
});
