/* eslint-disable no-console */
import { useCallback, useEffect, useMemo, useState } from "react";
import { useAuthContext } from "../../context/auth";
import { getIntermediarios } from "../../utils";
import "./styles.scss";

export const ListadoFacturas = ({ facturas, pantallasEdificio, comisionEdificioPorcentaje }) => {
  const [intermediarios, setIntermediarios] = useState([]);
  const [orden] = useState({ campo: "fechafactura", ascendente: true });
  const [totales, setTotales] = useState({ facturado: 0, comision: 0, comisionCobrada: 0 });

  const { pais } = useAuthContext();

  const localString = useCallback(
    (pais) =>
      ({
        Chile: "es-CL",
        Perú: "es-PE",
        Uruguay: "es-UY",
      })[pais] || "es-UY",
    []
  );

  const moneda = useCallback(
    (pais) =>
      ({
        Chile: "$ ",
        Perú: "S/ ",
        Uruguay: "$ ",
      })[pais] || "$ ",
    []
  );

  const decimales = useCallback(
    (pais) =>
      ({
        Chile: 0,
        Perú: 2,
        Uruguay: 0,
      })[pais] || 0,
    []
  );

  useEffect(() => {
    const fetchIntermediarios = async () => {
      const intermediariosData = await getIntermediarios();
      setIntermediarios(intermediariosData);
    };

    fetchIntermediarios();
  }, []);

  const getFacturadoYComision = useCallback(
    (importe, intermediarioNombre, pantallasEdificio, pantallastotales, pais) => {
      // Buscar el intermediario en la lista de intermediarios
      const intermediarioEncontrado = intermediarios.find((i) => i.nombre === intermediarioNombre && i.pais === pais);

      // Si se encuentra el intermediario, obtener su comisión, de lo contrario, asumir una comisión predeterminada
      const comisionIntermediario = intermediarioEncontrado ? intermediarioEncontrado.comision : 0;
      const intermediacion = importe * (comisionIntermediario / 100);
      const inversionNeta = importe - intermediacion;
      const participacionEdificioPorcentaje = pantallasEdificio / pantallastotales;
      const participacionEdificioPlata = participacionEdificioPorcentaje * inversionNeta;
      const comisionEdificio = participacionEdificioPlata * (comisionEdificioPorcentaje / 100);

      return { facturado: participacionEdificioPlata, comision: comisionEdificio };
    },
    [intermediarios, comisionEdificioPorcentaje]
  );

  const facturasOrdenadas = useMemo(
    () =>
      [...facturas].sort((a, b) => {
        const fechaA = new Date(a.facturafecha);
        const fechaB = new Date(b.facturafecha);
        return orden.ascendente ? fechaA - fechaB : fechaB - fechaA;
      }),
    [facturas, orden.ascendente]
  );

  useEffect(() => {
    const newTotales = { facturado: 0, comision: 0, comisionCobrada: 0 };
    facturas.forEach(({ importe, intermediariocomisionporcentaje, pantallastotales, facturafechacobro }) => {
      const { facturado, comision } = getFacturadoYComision(
        importe,
        intermediariocomisionporcentaje,
        pantallasEdificio,
        pantallastotales,
        pais
      );

      newTotales.facturado += facturado;
      newTotales.comision += comision;
      if (facturafechacobro) {
        newTotales.comisionCobrada += comision;
      }
    });

    setTotales(newTotales);
  }, [facturas, getFacturadoYComision, pantallasEdificio, orden]);

  return (
    <div className="table-responsive">
      <table className="table table-fixed table-striped table-bordered table-hover align-middle">
        <thead className="table-dark text-center align-middle">
          <tr>
            <th className="col-sm-1">NÚMERO FACTURA</th>
            <th className="col-sm-1">FECHA FACTURA</th>
            <th className="col-sm-1">CLIENTE</th>
            <th className="col-sm-1">INTERMEDIARIO</th>
            <th className="col-sm-1">IMPORTE NETO</th>
            <th className="col-sm-1">COMISIÓN {comisionEdificioPorcentaje}%</th>
            <th className="col-sm-1">¿COBRADA?</th>
          </tr>
        </thead>

        <tbody>
          {facturasOrdenadas.map((factura) => {
            const {
              cliente,
              facturafecha,
              facturafechacobro,
              facturanumero,
              id,
              importe,
              intermediario,
              pantallastotales,
            } = factura;
            const { facturado, comision } = getFacturadoYComision(
              importe,
              intermediario,
              pantallasEdificio,
              pantallastotales
            );

            return (
              <tr className="table-striped" key={id}>
                <td className="text-center">{facturanumero}</td>
                <td className="text-center">
                  {`${new Date(facturafecha).getUTCDate().toString().padStart(2, "0")}/${(new Date(facturafecha).getUTCMonth() + 1).toString().padStart(2, "0")}/${new Date(facturafecha).getUTCFullYear()}`}
                </td>
                <td className="text-center">{cliente}</td>
                <td className="text-center">{intermediario}</td>
                <td className="text-end">{`${moneda(pais)} ${facturado.toLocaleString(localString(pais), {
                  minimumFractionDigits: decimales(pais),
                  maximumFractionDigits: decimales(pais),
                })}`}</td>
                <td className="text-end">{`${moneda(pais)} ${comision.toLocaleString(localString(pais), {
                  minimumFractionDigits: decimales(pais),
                  maximumFractionDigits: decimales(pais),
                })}`}</td>
                <td
                  className="text-center"
                  style={{ backgroundColor: facturafechacobro ? "#198754" : "#dc3545", color: "white" }}
                >
                  {facturafechacobro ? "SÍ" : "NO"}
                </td>
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          <tr className="table-dark text-start align-middle">
            <td colSpan="4" className="col-sm-1">
              TOTALES DEL PERÍODO SELECCIONADO
            </td>
            <td className="text-end">
              {moneda(pais)}
              {totales.facturado.toLocaleString(localString(pais), {
                maximumFractionDigits: decimales(pais),
                minimumFractionDigits: decimales(pais),
              })}
            </td>
            <td className="text-end">
              {moneda(pais)}
              {totales.comision.toLocaleString(localString(pais), {
                maximumFractionDigits: decimales(pais),
                minimumFractionDigits: decimales(pais),
              })}
            </td>
            <td className="text-end">
              {moneda(pais)}
              {totales.comisionCobrada.toLocaleString(localString(pais), {
                maximumFractionDigits: decimales(pais),
                minimumFractionDigits: decimales(pais),
              })}
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};
