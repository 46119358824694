import { useCallback, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { ImCheckboxChecked, ImCheckboxUnchecked } from "react-icons/im";
import cx from "classix";
import { Loader } from "..";
import { useAuthContext } from "../../context/auth";
import { getEdificiosOperativos } from "../../utils";
import "./styles.scss";

export const ListaEdificios = ({ forceSubmit, edificiosPreseleccionados }) => {
  const [edificios, setEdificios] = useState();
  const [checkAll, setCheckAll] = useState(false);

  const { pais } = useAuthContext();

  const localString = useCallback(
    (pais) =>
      ({
        Chile: "es-CL",
        Perú: "es-PE",
        Uruguay: "es-UY",
      })[pais] || "es-UY",
    []
  );

  const { getValues, register, setValue, watch } = useFormContext();

  const allCheckbox = watch();

  const actualizarStringEdificios = useCallback((edificiosSeleccionados, id, agregar) => {
    // Coaccionar el valor de edificiosSeleccionados a una cadena de texto
    edificiosSeleccionados = String(edificiosSeleccionados);

    if (agregar) return edificiosSeleccionados === "" ? id : `${edificiosSeleccionados},${id}`;
    else
      return edificiosSeleccionados
        .split(",")
        .filter((n) => n !== id.toString())
        .join(",");
  }, []);

  useEffect(() => {
    const initialize = async () => {
      const newEdificios = await getEdificiosOperativos({ pais });
      setEdificios(newEdificios.sort((a, b) => a.edificio.localeCompare(b.edificio)));
      setValue("edificiosTotales", "0");
      setValue("pantallasTotales", "0");
      setValue("personasTotales", "0");
      setValue("edificiosSeleccionados", "");
    };

    initialize();
  }, [setValue, edificiosPreseleccionados, pais]);

  const toggleCheck = useCallback(
    (id, newStatus) => {
      const names = {
        check: `edificio-${id}-checkbox`,
        edificiosSeleccionados: "edificiosSeleccionados",
        edificiosTotales: "edificiosTotales",
        pantallas: `edificio-${id}-pantallas`,
        pantallasTotales: "pantallasTotales",
        personas: `edificio-${id}-personas`,
        personasTotales: "personasTotales",
      };
      const [check, edificiosTotales, pantallas, pantallasTotales, personas, personasTotales, edificiosSeleccionados] =
        getValues([
          names.check,
          names.edificiosTotales,
          names.pantallas,
          names.pantallasTotales,
          names.personas,
          names.personasTotales,
          names.edificiosSeleccionados,
        ]);

      const newCheckValue = newStatus !== undefined ? newStatus : !check;
      setValue(names.check, newCheckValue);

      const selectedEdificios = newCheckValue ? Number(edificiosTotales) + 1 : Number(edificiosTotales) - 1;
      const selectedPantallas = newCheckValue
        ? Number(pantallasTotales) + Number(pantallas)
        : Number(pantallasTotales) - Number(pantallas);

      setValue(names.edificiosTotales, selectedEdificios < 0 ? 0 : selectedEdificios);
      setValue(names.pantallasTotales, selectedPantallas < 0 ? 0 : selectedPantallas);

      const newPersonasTotalesValue = newCheckValue
        ? Number(personasTotales) + Number(personas)
        : Number(personasTotales) - Number(personas);
      setValue(names.personasTotales, newPersonasTotalesValue);

      const newEdificiosSeleccionados = actualizarStringEdificios(edificiosSeleccionados, id, newCheckValue);
      setValue(names.edificiosSeleccionados, newEdificiosSeleccionados);

      if (newStatus === undefined && checkAll === true) setCheckAll(false);

      forceSubmit && forceSubmit();
    },
    [getValues, setValue, forceSubmit, actualizarStringEdificios, checkAll]
  );

  const toggleCheckAll = useCallback(() => {
    // Si checkAll está activado, activa todos los edificios y actualiza los totales
    if (!checkAll) {
      let totalEdificios = 0;
      let totalPantallas = 0;

      edificios.forEach(({ id, pantallas }) => {
        toggleCheck(id, true);
        totalEdificios++;
        totalPantallas += Number(pantallas);
      });

      setValue("edificiosTotales", totalEdificios);
      setValue("pantallasTotales", totalPantallas);
      setCheckAll(true);
    } else {
      // Si checkAll no está activado, desactiva todos los edificios y actualiza los totales a cero
      edificios.forEach(({ id }) => toggleCheck(id, false));
      setValue("edificiosTotales", 0);
      setValue("pantallasTotales", 0);
      setCheckAll(false);
    }
  }, [edificios, toggleCheck, checkAll, setValue]);

  useEffect(() => {
    const initialize = async () => {
      const newEdificios = await getEdificiosOperativos({ pais });
      setEdificios(newEdificios.sort((a, b) => a.edificio.localeCompare(b.edificio)));
      setValue("edificiosTotales", "0");
      setValue("pantallasTotales", "0");
      setValue("personasTotales", "0");
      setValue("edificiosSeleccionados", "");

      // Verificar si preseleccionadosArray tiene elementos
      if (edificiosPreseleccionados && edificiosPreseleccionados.trim() !== "") {
        const preseleccionadosArray = edificiosPreseleccionados.split(",").map(Number);

        // Verificar si preseleccionadosArray tiene elementos
        if (preseleccionadosArray.length > 0) {
          preseleccionadosArray.forEach((id) => toggleCheck(id, true));
        }
      }
    };

    initialize();
  }, [setValue, edificiosPreseleccionados, pais]);

  if (edificios === undefined) return <Loader />;

  return (
    <div style={{ width: "75%", margin: "0 auto", marginTop: "3rem" }}>
      <table className="table table-striped table-hover table-sm">
        <thead className="table-dark">
          <tr>
            <th scope="col" className="text-center" onClick={toggleCheckAll}>
              {checkAll ? (
                <ImCheckboxChecked className="icon" size={18} />
              ) : (
                <ImCheckboxUnchecked className="icon" size={18} />
              )}
            </th>
            <th scope="col" className="text-center">
              EDIFICIO
            </th>
            <th scope="col" className="text-center">
              UBICACIÓN
            </th>
            <th scope="col" className="text-center">
              PANTALLAS
            </th>
            <th scope="col" className="text-center">
              PERSONAS ÚNICAS
            </th>
          </tr>
        </thead>

        <tbody>
          {edificios.map(({ created_at, edificio, id, pantallas, personas, ubicacion }) => (
            <tr
              key={created_at}
              onClick={() => toggleCheck(id)}
              className={cx(allCheckbox[`edificio-${id}-checkbox`] === true && "table-success")}
            >
              <td className="text-center">
                {allCheckbox[`edificio-${id}-checkbox`] ? (
                  <ImCheckboxChecked className="icon" size={18} />
                ) : (
                  <ImCheckboxUnchecked className="icon" size={18} />
                )}
                <input hidden type="checkbox" value={edificio} {...register(`edificio-${id}-checkbox`)} />
                <input hidden type="text" value={pantallas} {...register(`edificio-${id}-pantallas`)} />
                <input hidden type="text" value={personas} {...register(`edificio-${id}-personas`)} />
              </td>
              <td className="text-center">{edificio.toUpperCase()}</td>
              <td className="text-center">{ubicacion}</td>
              <td className="text-center">{pantallas}</td>
              <td className="text-center">{personas.toLocaleString(localString(pais))}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
