/* eslint-disable unicorn/prefer-module */
import React, { useMemo } from "react";
import { useAuthContext } from "../../context/auth";
import "./styles.scss";

export const Home = () => {
  const { pais } = useAuthContext();

  const logo = useMemo(
    () =>
      ({
        Chile: require("../../img/wecast-logo-transparente.png"),
        Perú: require("../../img/visiona-logo-transparente.png"),
        Uruguay: require("../../img/screenmedia-logo-transparente.png"),
      })[pais] || require("../../img/visiona-logo-transparente.png"),
    [pais]
  );

  const mail = useMemo(
    () =>
      ({
        Chile: "hola@wecast.cl",
        Perú: "hola@visiona.pe",
        Uruguay: "hola@screenmedia.com.uy",
      })[pais] || "hola@visiona.pe",
    [pais]
  );

  return (
    <main className="Home">
      <div className="container">
        <div className="container-auxiliar">
          <img src={logo} alt="Logo" className="logo" />
          <p className="bajada">Impacte audiencias corporativas B2C & B2B con precisión y eficiencia</p>
          <p className="correo">{mail}</p>
        </div>
      </div>
    </main>
  );
};
