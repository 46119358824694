import { useState, useEffect } from "react";
import { Container, UsuarioList } from "../../components";
import { getUsers } from "../../utils/getUsers";
import "./styles.scss";

export const Usuarios = () => {
  const [usuarios, setUsuarios] = useState([]);

  useEffect(() => {
    const initialize = async () => {
      const newUsuarios = await getUsers();
      setUsuarios(newUsuarios);
    };

    initialize();
  }, []);

  return (
    <Container className="Usuarios" header titulo="Usuarios">
      <div className="encabezados">
        <div className="titulo">ROL</div>
        <div className="titulo">NOMBRE</div>
        <div className="titulo">APELLIDO</div>
        <div className="titulo">CORREO</div>
        <div className="titulo">EDIT</div>
      </div>
      <ul className="usuario-list">
        <div>{usuarios ? usuarios.map((manzanas) => <UsuarioList key={manzanas.id} {...manzanas} />) : "Loading"}</div>
      </ul>
    </Container>
  );
};
