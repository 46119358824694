/* eslint-disable no-console */
import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { toast } from "react-hot-toast";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from "reactstrap";
import cx from "classix";
import { Container, ListaEdificios, Loader } from "..";
import { useAuthContext } from "../../context/auth";
import { log, getClientesPublicitarios, quitarCaracteresEspeciales, saveOportunidad } from "../../utils";
import "./styles.scss";

export const CargaOportunidad = () => {
  const [clientesPublicitarios, setClientesPublicitarios] = useState();
  const [showModalConfirmacion, setShowModalConfirmacion] = useState(false);
  const [temporalDataForm, setTemporalDataForm] = useState();
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const [selectedAgency, setSelectedAgency] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [atLeastOneBuildingSelected, setAtLeastOneBuildingSelected] = useState(false);
  const [edificiosSeleccionadosCount, setEdificiosSeleccionadosCount] = useState(0);

  const { pais } = useAuthContext();

  const methods = useForm();

  const {
    formState: { errors },
    handleSubmit,
    register,
    reset,
    setValue,
    watch,
  } = methods;

  const edificiosSeleccionados = watch("edificiosSeleccionados");

  const months = [
    { value: "01", label: "ENE" },
    { value: "02", label: "FEB" },
    { value: "03", label: "MAR" },
    { value: "04", label: "ABR" },
    { value: "05", label: "MAY" },
    { value: "06", label: "JUN" },
    { value: "07", label: "JUL" },
    { value: "08", label: "AGO" },
    { value: "09", label: "SET" },
    { value: "10", label: "OCT" },
    { value: "11", label: "NOV" },
    { value: "12", label: "DIC" },
  ];

  const selectOptions = months.map((month) => (
    <option key={month.value} value={month.value}>
      {month.label}
    </option>
  ));

  const onSubmit = useCallback(
    (data) => {
      setShowModalConfirmacion(true);

      const newOportunidad = {
        fechaOportunidad: data.fechaOportunidad,
        cliente: quitarCaracteresEspeciales(data.clientePublicitario),
        mes: data.mes,
        importe: data.importe,
        estatus: data.estatus,
        ejecutivo: data.ejecutivo,
        intermediario: selectedAgency,
        categoria: selectedCategory,
        edificiosparticipantes: data.edificiosSeleccionados,
        edificiostotales: data.edificiosTotales,
        pantallastotales: data.pantallasTotales,
        formatopauta: data.formatopauta,
        periodopauta: Number(data.periodopauta),
        duracion: Number(data.duracion),
        pais,
      };

      setTemporalDataForm(newOportunidad);
    },
    [pais, selectedAgency, selectedCategory]
  );

  const handleBuildingSelection = useCallback(() => {
    const count = edificiosSeleccionados ? edificiosSeleccionados.length : 0;
    setEdificiosSeleccionadosCount(count);
  }, [edificiosSeleccionados]);
  useEffect(() => {
    handleBuildingSelection();
  }, [handleBuildingSelection]);

  const saveButtonHandle = useCallback(async () => {
    if (edificiosSeleccionadosCount === 0) {
      toast.error("Debe seleccionar al menos un edificio.");
      return;
    }
    try {
      setShowModalConfirmacion(false);
      await saveOportunidad(temporalDataForm);
      reset();
      setValue("edificiosTotales", 0);
      setValue("pantallasTotales", 0);
      setValue("personasTotales", "0");
      toast.success("La oportunidad se guardó correctamente");
      setTimeout(() => {
        setShouldRefresh(true);
      }, 2000);
    } catch (error) {
      toast.error("Hubo un error al guardar la oportunidad, prueba de nuevo.");
      log.error("saveButtonHandle", error);
    }
  }, [atLeastOneBuildingSelected, temporalDataForm, reset, setValue]);

  const validarSelect = useCallback((value) => value !== "0", []);

  const disableSubmit = useMemo(() => showModalConfirmacion, [showModalConfirmacion]);

  useEffect(() => {
    const initialize = async () => {
      const newClientesPublicitarios = await getClientesPublicitarios({ pais });
      setClientesPublicitarios(newClientesPublicitarios);
    };

    initialize();
  }, [pais]);

  useEffect(() => {
    if (edificiosSeleccionados && edificiosSeleccionados.length > 0) {
      console.log(edificiosSeleccionados);
      setAtLeastOneBuildingSelected(true);
    } else {
      setAtLeastOneBuildingSelected(false);
    }
  }, [edificiosSeleccionados]);

  useEffect(() => {
    if (shouldRefresh) {
      window.location.reload();
    }
  }, [shouldRefresh]);

  const cantidadDias = useMemo(() => Array.from({ length: 260 }, (_, i) => i + 1), []);
  const cantidadSegundos = useMemo(() => Array.from({ length: 10 }, (_, i) => i + 6), []);

  useEffect(() => {
    if (clientesPublicitarios && clientesPublicitarios.length > 0) {
      const clienteSeleccionado = clientesPublicitarios.find(
        (cliente) => cliente.nombre === methods.getValues("clientePublicitario")
      );
      if (clienteSeleccionado) {
        setSelectedAgency(clienteSeleccionado.agencia);
        setSelectedCategory(clienteSeleccionado.categoria);
      }
    }
  }, [clientesPublicitarios, methods.getValues("clientePublicitario")]);

  return (
    <Container className="CargarFactura" header titulo="CARGA DE OPORTUNIDAD">
      {clientesPublicitarios ? (
        <FormProvider {...methods}>
          <form className="row g-4 mt-2 h-100" onSubmit={handleSubmit(onSubmit)}>
            <div className="col-3 text-start fw-bold">
              <label htmlFor="clientePublicitario" className="form-label">
                CLIENTE
              </label>
              <select
                className={cx("form-select", errors?.clientePublicitario && "is-invalid")}
                id="clientePublicitario"
                defaultValue="0"
                {...register("clientePublicitario", { validate: validarSelect })}
              >
                <option disabled value="0">
                  Selecciona un cliente
                </option>

                {clientesPublicitarios && clientesPublicitarios.length > 0 ? (
                  clientesPublicitarios.map(({ nombre, id }) => (
                    <option key={id} value={nombre}>
                      {nombre}
                    </option>
                  ))
                ) : (
                  <option value="">No hay clientes disponibles</option>
                )}
              </select>

              <div className="invalid-feedback">Este campo es obligatorio</div>
            </div>

            <div className="col-3 text-start fw-bold">
              <label htmlFor="importe" className="form-label">
                TICKET
              </label>
              <input
                className={cx("form-control", errors?.importe && "is-invalid")}
                id="importe"
                placeholder="Inversión"
                type="text"
                inputMode="numeric"
                pattern="[0-9]*"
                {...register("importe", { required: true, min: 0 })}
              />

              <div className="invalid-feedback">Este campo es obligatorio</div>
            </div>

            <div className="col-3 text-start fw-bold">
              <label htmlFor="estatus" className="form-label">
                STATUS
              </label>
              <select
                className={cx("form-control", errors?.status && "is-invalid")}
                id="estatus"
                {...register("estatus", { required: true })}
              >
                <option value="" hidden>
                  Seleccionar %
                </option>
                <option value="100%">100%</option>
                <option value="90%">90%</option>
                <option value="75%">75%</option>
                <option value="50%">50%</option>
              </select>

              <div className="invalid-feedback">Este campo es obligatorio</div>
            </div>
            <div className="col-3 text-start fw-bold">
              <label htmlFor="mes" className="form-label">
                MES
              </label>
              <select
                className={cx("form-control", errors?.mes && "is-invalid")}
                id="mes"
                type="text"
                {...register("mes", { required: true })}
              >
                <option value="">¿Cuándo se facturará?</option>
                {selectOptions}
              </select>

              <div className="invalid-feedback">Este campo es obligatorio</div>
            </div>

            <div className="col-3 text-start fw-bold">
              <label htmlFor="formatopauta" className="form-label">
                FRECUENCIA
              </label>
              <select
                className={cx("form-control", errors?.formatopauta && "is-invalid")}
                id="formatopauta"
                {...register("formatopauta", { required: true })}
              >
                <option value="" hidden>
                  ¿Qué formato de pauta?
                </option>
                <option value="A">FORMATO A</option>
                <option value="B">FORMATO B</option>
                <option value="C">FORMATO C</option>
              </select>

              <div className="invalid-feedback">Este campo es obligatorio</div>
            </div>

            <div className="col-3 text-start fw-bold">
              <label htmlFor="periodopauta" className="form-label">
                PERÍODO DE PAUTA
              </label>
              <select
                className={cx("form-control", errors?.periodopauta && "is-invalid")}
                id="periodopauta"
                {...register("periodopauta", { required: true })}
              >
                <option value="" hidden>
                  Cantidad de días
                </option>
                {cantidadDias.map((cantidadDias) => (
                  <option key={cantidadDias}>{cantidadDias}</option>
                ))}
              </select>
            </div>

            <div className="col-3 text-start fw-bold">
              <label htmlFor="duracion" className="form-label">
                DURACIÓN DE LA PAUTA
              </label>
              <select
                className={cx("form-control", errors?.duracion && "is-invalid")}
                id="duracion"
                {...register("duracion", { required: true })}
              >
                <option value="" hidden>
                  Cantidad de segundos
                </option>
                {cantidadSegundos.map((sec) => (
                  <option key={sec}>{sec}</option>
                ))}
              </select>
            </div>

            <div className="col-3 text-start fw-bold">
              <label htmlFor="ejecutivo" className="form-label">
                EJECUTIVO
              </label>
              <select
                className={cx("form-control", errors?.ejecutivo && "is-invalid")}
                id="ejecutivo"
                {...register("ejecutivo", { required: true })}
              >
                <option value="" hidden>
                  ¿Quién lidera la oportunidad?
                </option>
                {pais === "Perú" && (
                  <>
                    <option value="Santiago">Santiago</option>
                    <option value="Daniela">Daniela</option>
                  </>
                )}
                {pais === "Chile" && (
                  <>
                    <option value="Gerencia">Gerencia</option>
                  </>
                )}
                {pais === "Uruguay" && <option value="Diego">Diego</option>}
                <option value="Ignacio">Ignacio</option>
              </select>
              <div className="invalid-feedback">Este campo es obligatorio</div>
            </div>

            <div className="col-3 text-start fw-bold">
              <label htmlFor="selectedAgency" className="form-label">
                AGENCIA
              </label>
              <input
                type="text"
                className={cx("form-control", errors?.selectedAgency && "is-invalid")}
                id="selectedAgency"
                value={selectedAgency}
                readOnly
                style={{ backgroundColor: "#e9ecef", color: "#6c757d" }} // Gris un poco más oscuro
              />
            </div>

            <div className="col-3 text-start fw-bold">
              <label htmlFor="selectedCategory" className="form-label">
                CATEGORÍA
              </label>
              <input
                type="text"
                className={cx("form-control", errors?.selectedCategory && "is-invalid")}
                id="selectedCategory"
                value={selectedCategory}
                readOnly
                style={{ backgroundColor: "#e9ecef", color: "#6c757d" }} // Gris un poco más oscuro
              />
            </div>

            <div className="col-3 text-start fw-bold">
              <label htmlFor="edificiosTotales" className="form-label">
                EDIFICIOS
              </label>
              <input
                className={cx("form-control", errors?.edificiosTotales && "is-invalid")}
                defaultValue={0}
                disabled
                id="edificiosTotales"
                type="number"
                {...register("edificiosTotales", { required: true })}
              />
            </div>

            <div className="col-3 text-start fw-bold">
              <label htmlFor="pantallasTotales" className="form-label">
                PANTALLAS
              </label>
              <input
                className={cx("form-control", errors?.pantallasTotales && "is-invalid")}
                defaultValue={0}
                disabled
                id="pantallasTotales"
                type="number"
                {...register("pantallasTotales", { required: true })}
              />
            </div>
            <div className="col-12 mt-5 d-flex align-items-end justify-content-center" style={{ minHeight: "100%" }}>
              <button
                className="btn btn-primary"
                style={{ minHeight: "fit-content" }}
                type="submit"
                disabled={disableSubmit}
              >
                {!disableSubmit ? (
                  "GUARDAR OPORTUNIDAD"
                ) : (
                  <>
                    <Spinner size="sm">GUARDANDO...</Spinner>
                    <span> GUARDANDO</span>
                  </>
                )}
              </button>
            </div>

            <ListaEdificios />

            <input hidden type="text" {...register("personasTotales")} />
            <input hidden type="text" {...register("edificiosSeleccionados")} />
          </form>

          <Modal isOpen={showModalConfirmacion} toggle={() => setShowModalConfirmacion(false)}>
            <ModalHeader>¿Está seguro?</ModalHeader>
            <ModalBody>Se está por guardar una nueva oportunidad</ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={() => setShowModalConfirmacion(false)}>
                Cancelar
              </Button>
              <Button color="primary" onClick={saveButtonHandle}>
                Guardar
              </Button>
            </ModalFooter>
          </Modal>
        </FormProvider>
      ) : (
        <Loader />
      )}
    </Container>
  );
};
