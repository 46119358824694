import { useState } from "react";
import { useFormContext } from "react-hook-form";
import cx from "classix";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import "./styles.scss";

export const PeriodoPautaCalendario = ({ className }) => {
  const [datePickerValue, setDatePickerValue] = useState([new Date(), new Date()]);

  const { setValue } = useFormContext();

  const fechasSeleccionadas = ([startDate, endDate]) => {
    setDatePickerValue([startDate, endDate]);

    let periodoDePauta = 0;
    const curDate = new Date(startDate.getTime());
    while (curDate <= endDate) {
      const dayOfWeek = curDate.getDay();
      if (dayOfWeek !== 0 && dayOfWeek !== 6) periodoDePauta++;
      curDate.setDate(curDate.getDate() + 1);
    }
    setValue("periodoDePauta", periodoDePauta);

    const monthNames = ["ENE", "FEB", "MAR", "ABR", "MAY", "JUN", "JUL", "AUG", "SET", "OCT", "NOV", "DEC"];

    const fechaInicio = new Date(startDate);
    if (startDate.getDay() === 0) fechaInicio.setDate(startDate.getDate() - 1);
    else if (startDate.getDay() === 6) fechaInicio.setDate(startDate.getDate() + 2);

    const fecha = fechaInicio.getDate();
    const mes = monthNames[fechaInicio.getMonth()];
    const periodoPautaInicio = fecha + mes;
    setValue("periodoPautaInicio", periodoPautaInicio);

    const fechaFinalizacion = new Date(endDate);
    if (endDate.getDay() === 6) fechaFinalizacion.setDate(endDate.getDate() - 1);
    else if (endDate.getDay() === 0) fechaFinalizacion.setDate(endDate.getDate() - 2);

    const fechaB = fechaFinalizacion.getDate();
    const mesB = monthNames[fechaFinalizacion.getMonth()];
    const periodoPautaFin = fechaB + mesB;
    setValue("periodoPautaFin", periodoPautaFin);
  };

  return (
    <div className={cx("PeriodoPautaCalendario", className)}>
      <div className="form-floating">
        <DateRangePicker
          className="form-control"
          onChange={fechasSeleccionadas}
          value={datePickerValue}
          locale="es-ES"
        />
        <label>4. Plazo pauta (rango de fechas)</label>
      </div>
    </div>
  );
};
