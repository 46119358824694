import { useFormContext } from "react-hook-form";
import cx from "classix";
import Ayuda from "../Ayuda";

export const ListaFormatos = ({ className }) => {
  const { register } = useFormContext();

  return (
    <div className={cx("formatos", className)} style={{ position: "relative" }}>
      <div className="form-floating">
        <select className="form-select" {...register("formato")}>
          <option disabled value="0">
            Selecciona una opción
          </option>
          <option value="FORMATO-A">FORMATO A: 70</option>
          <option value="FORMATO-B">FORMATO B: 140</option>
          <option value="FORMATO-C">FORMATO C: 280</option>
        </select>
        <label>2. Frecuencia de pauta</label>
      </div>
      <div style={{ position: "absolute", top: "0", right: "0" }}>
        <Ayuda
          textoAyuda={[
            "A => 70 APARICIONES POR DÍA POR PANTALLA: 'REGULARES' DE 1 O MÁS MESES",
            "B => 140 APARICIONES POR DÍA POR PANTALLA: 'TÁCTICAS' DE 1 A 3 SEMANAS",
            "C => 280 APARICIONES POR DÍA POR PANTALLA: 'HIPER TÁCTICAS' DE 1 A 4 DÍAS",
          ]}
        />
      </div>
    </div>
  );
};
