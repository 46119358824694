import { useMemo } from "react";
import cx from "classix";

export const LoginFeedback = ({ status, className }) => {
  const message = useMemo(
    () => ({
      sending_link: "Enviando magic link...",
      check_email: "Estamos OK, por favor revisa tu correo para el login :)",
    }),
    []
  );

  return <div className={cx("LoginFeedback", className)}>{message[status]}</div>;
};
