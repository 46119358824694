import { useFormContext } from "react-hook-form";
import cx from "classix";
import Ayuda from "../Ayuda";

export const ListaProductos = ({ className }) => {
  const { register } = useFormContext();

  return (
    <div className={cx("productos", className)} style={{ position: "relative" }}>
      <div className="form-floating">
        <select className="form-select" {...register("producto")}>
          <option disabled value="0">
            Selecciona una opción
          </option>
          <option value="TODALARED">TODA LA RED</option>
          <option value="POREDIFICIO">POR EDIFICIO</option>
        </select>
        <label>1. Inventario</label>
      </div>
      <div style={{ position: "absolute", top: "0", right: "0" }}>
        <Ayuda textoAyuda={["SELECCIONE EL INVENTARIO", "DONDE SE EMITIRÁ SU CAMPAÑA"]} />
      </div>
    </div>
  );
};
