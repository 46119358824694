export const calculatePricePerSecond = ({ producto, formato, pais }) => {
  const precioPorSegundo = {
    Perú: 0.0146952,
    Uruguay: 0.166,
    Chile: 4.68,
  };

  const productPrices = {
    POREDIFICIO: precioPorSegundo[pais],
    PREMIUM: precioPorSegundo[pais],
    TODALARED: precioPorSegundo[pais],
  };

  const modifierByFormat = {
    "FORMATO-A": 1,
    "FORMATO-B": 0.9,
    "FORMATO-C": 0.9 * 0.9,
  };

  const newPricePerSecond = productPrices[producto] * modifierByFormat[formato];

  return newPricePerSecond;
};
