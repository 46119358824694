import { useCallback, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import cx from "classix";
import { useAuthContext } from "../../context/auth";
import "./styles.scss";

export const CotizarDias = ({ className }) => {
  const { pais } = useAuthContext();

  const { watch } = useFormContext();

  const [
    duracionSelected,
    plazoSelected,
    pricePerSecond,
    dailySpots,
    screenAmount,
    peopleAmount,
    buildingAmount,
    inversionFria,
    discountInventoryPercentage,
    discountInventoryMoney,
    discountTimePercentage,
    discountTimeMoney,
    finalInvestment,
  ] = watch([
    "duracion",
    "periodoDePauta",
    "pricePerSecond",
    "dailySpots",
    "pantallasTotales",
    "personasTotales",
    "edificiosTotales",
    "inversionFria",
    "discountInventoryPercentage",
    "discountInventoryMoney",
    "discountTimePercentage",
    "discountTimeMoney",
    "finalInvestment",
  ]);

  const localString = useCallback(
    (pais) =>
      ({
        Chile: "es-CL",
        Perú: "es-PE",
        Uruguay: "es-UY",
      })[pais] || "es-UY",
    []
  );

  const moneda = useCallback(
    (pais) =>
      ({
        Chile: "$",
        Perú: "S/",
        Uruguay: "$",
      })[pais] || "$",
    []
  );

  const monedaTextual = useCallback(
    (pais) =>
      ({
        Chile: "PESOS CHILENOS",
        Perú: "SOLES PERUANOS",
        Uruguay: "PESOS URUGUAYOS",
      })[pais] || "PESOS URUGUAYOS",
    []
  );

  const peopleAmountFormateado = useMemo(
    () => Number(peopleAmount).toLocaleString(localString(pais), { maximumFractionDigits: 0 }),
    [peopleAmount, localString, pais]
  );

  const dailySpotsFormateado = useMemo(
    () => Number(dailySpots).toLocaleString(localString(pais), { maximumFractionDigits: 0 }),
    [dailySpots, localString, pais]
  );

  const inversionFriaFormateado = useMemo(
    () => Number(inversionFria).toLocaleString(localString(pais), { maximumFractionDigits: 0 }),
    [inversionFria, localString, pais]
  );

  const discountInventoryPercentageFormateado = useMemo(
    () =>
      (Number(discountInventoryPercentage) * 100).toLocaleString(localString(pais), {
        maximumFractionDigits: 1,
        minimumFractionDigits: 1,
      }),
    [discountInventoryPercentage, localString, pais]
  );

  const discountInventoryMoneyFormateado = useMemo(
    () =>
      Number(discountInventoryMoney).toLocaleString(localString(pais), {
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      }),
    [discountInventoryMoney, localString, pais]
  );

  const discountTimePercentageFormateado = useMemo(
    () =>
      (Number(discountTimePercentage) * 100).toLocaleString(localString(pais), {
        maximumFractionDigits: 1,
        minimumFractionDigits: 1,
      }),
    [discountTimePercentage, localString, pais]
  );

  const discountTimeMoneyFormateado = useMemo(
    () =>
      Number(discountTimeMoney).toLocaleString(localString(pais), {
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      }),
    [discountTimeMoney, localString, pais]
  );

  const finalInvestmentFormateado = useMemo(
    () =>
      Number(finalInvestment).toLocaleString(localString(pais), {
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      }),
    [finalInvestment, localString, pais]
  );

  return (
    <div className={cx("Cotizar", className)}>
      <div className="cotizacion">
        <div className="left">Cantidad de edificios:</div>
        <div className="right">{buildingAmount}</div>
        <div className="left">Cantidad de pantallas:</div>
        <div className="right">{screenAmount}</div>
        <div className="left">Cantidad de personas:</div>
        <div className="right">{peopleAmountFormateado}</div>
        <div className="left">Duración pieza publicitaria:</div>
        <div className="right">{duracionSelected} segundos</div>
        <div className="left">Duración de la pauta:</div>
        <div className="right">{`${plazoSelected} días`}</div>
        <div className="left">Cantidad de spots por día:</div>
        <div className="right">{dailySpotsFormateado}</div>
        <div className="left">Precio del segundo:</div>
        <div className="right">{`${moneda(pais)} ${pricePerSecond.toLocaleString(localString(pais), {
          minimumFractionDigits: 3,
          maximumFractionDigits: 3,
        })}`}</div>
        <div className="left">Inversión (tarifa fría):</div>
        <div className="right">{`${moneda(pais)} ${inversionFriaFormateado}`}</div>
        <div className="left">Descuento por inventario:</div>
        <div className="right">
          {`${discountInventoryPercentageFormateado}% ----> `}
          <span className="descuento">{`-${moneda(pais)} ${discountInventoryMoneyFormateado}`}</span>
        </div>
        <div className="left">Descuento por tiempo de compra:</div>
        <div className="right">
          {`${discountTimePercentageFormateado}%  ----> `}
          <span className="descuento">{`-${moneda(pais)} ${discountTimeMoneyFormateado}`}</span>
        </div>
        <div className="left">Inversión final:</div>
        <div className="right">{`${moneda(pais)} ${finalInvestmentFormateado}`}</div>
      </div>
      <p className="letraChiquita">
        Propuesta exclusiva para agencia y/o cliente cotizado. Importes expresados en {monedaTextual(pais)} y no
        incluyen impuestos. Salidas según formato contratado y de lunes a viernes de 7 AM a 9 PM. Propuesta incluye
        descuentos especiales que no podrán considerarse como antecedente. El cliente podrá cambiar materiales tantas
        veces como desee. Cotización válida para pautas a emitirse hasta diciembre 2024 inclusive.
      </p>
    </div>
  );
};
