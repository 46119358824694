import { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import cx from "classix";
import Ayuda from "../Ayuda";

export const ListaPlazoPautaDias = ({ className }) => {
  const { register } = useFormContext();
  const cantidadDias = useMemo(() => Array.from({ length: 260 }, (_, i) => i + 1), []);

  return (
    <div className={cx("plazopauta", className)} style={{ position: "relative" }}>
      <div className="form-floating">
        <select className="form-select" {...register("periodoDePauta")}>
          <option disabled value="0">
            Selecciona una opción
          </option>
          {cantidadDias.map((cantidadDias) => (
            <option key={cantidadDias}>{cantidadDias}</option>
          ))}
        </select>
        <label>4. Plazo de pauta (días)</label>
      </div>
      <div style={{ position: "absolute", top: "0", right: "0" }}>
        <Ayuda
          textoAyuda={[
            "EMISIÓN: LUNES A VIERNES ENTRE LAS 07:00 & 21:00",
            "CADA 'SEMANA' = 5 DÍAS",
            "CADA 'MES' = 21 DÍAS (promedio)",
          ]}
        />
      </div>
    </div>
  );
};
