import { Route, Routes, Navigate } from "react-router-dom";
import { Loader } from "./components";
import { useAuthContext } from "./context/auth";
import {
  Admin,
  Api,
  CalculadorComisionEdificio,
  CargarCliente,
  CargarFactura,
  CargarIntermediario,
  CargarOportunidad,
  Cotizador,
  CotizadorCalendario,
  CotizadorDias,
  CotizadorDiasFrio,
  Home,
  Inventario,
  ListadoB2B,
  ListadoB2Bnuevo,
  Login,
  Mapa,
  Mediakit,
  MediakitEdificios,
  NotFound,
  Oportunidad,
  OportunidadFactura,
  Perfil,
  Portafolio,
  PortafolioFichaEdificios,
  Redirect,
  Usuarios,
  VentasDashboard,
  VentasDetalle,
  VentasFactura,
  VentasProyeccion,
  VentasRegion,
  VentasRegionDashboard,
  VentasRegionDetalle,
  VentasRegionReciente,
} from "./pages";

const App = () => {
  const { session } = useAuthContext();

  if (session === undefined) return <Loader fullPage />;

  return (
    <Routes>
      <Route index element={<Home />} />
      <Route path="/bitcoinapi" element={<Api />} />
      <Route path="/cotizador" element={<CotizadorDiasFrio />} />
      <Route path="/inventario" element={<Inventario />} />
      <Route path="/listadoB2B" element={<ListadoB2B />} />
      <Route path="/listadoempresas" element={<ListadoB2Bnuevo />} />
      <Route path="/mapa" element={<Mapa />} />
      <Route path="/mediakit" element={<Mediakit />} />
      <Route path="/mediakit-edificios" element={<MediakitEdificios />} />
      <Route path="/portafolio" element={<Portafolio />} />
      <Route path="/portafolionuevo" element={<PortafolioFichaEdificios />} />
      <Route path="/redirect" element={<Redirect />} />

      {session !== null ? (
        <>
          <Route path="/admin" element={<Admin />} />
          <Route path="/admin/cargar-cliente" element={<CargarCliente />} />
          <Route path="/admin/cargar-factura" element={<CargarFactura />} />
          <Route path="/admin/cargar-intermediario" element={<CargarIntermediario />} />
          <Route path="/admin/cargar-oportunidad" element={<CargarOportunidad />} />
          <Route path="/admin/factura/:oportunidadId" element={<OportunidadFactura />} />
          <Route path="/admin/informes-comision" element={<CalculadorComisionEdificio />} />
          <Route path="/admin/online-cotizador" element={<Cotizador />} />
          <Route path="/admin/online-cotizador/calendario" element={<CotizadorCalendario />} />
          <Route path="/admin/online-cotizador/dias" element={<CotizadorDias />} />
          <Route path="/admin/oportunidad/:oportunidadId" element={<Oportunidad />} />
          <Route path="/admin/perfil" element={<Perfil />} />
          <Route path="/admin/usuarios" element={<Usuarios />} />
          <Route path="/admin/ventas-dashboard" element={<VentasDashboard />} />
          <Route path="/admin/ventas-detalle" element={<VentasDetalle />} />
          <Route path="/admin/ventas-facturas" element={<VentasFactura />} />
          <Route path="/admin/ventas-proyeccion" element={<VentasProyeccion />} />
          <Route path="/admin/ventas-region" element={<VentasRegion />} />
          <Route path="/admin/ventas-region-dashboard" element={<VentasRegionDashboard />} />
          <Route path="/admin/ventas-region-detalle" element={<VentasRegionDetalle />} />
          <Route path="/admin/ventas-region-recientes" element={<VentasRegionReciente />} />
          <Route path="/login" element={<Navigate to="/admin" />} />
        </>
      ) : (
        <>
          <Route path="*" element={<Navigate to="/login" />} />
          <Route path="/login" element={<Login />} />
        </>
      )}

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default App;
