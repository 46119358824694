/* eslint-disable unicorn/prefer-module */
import { useEffect, useState, useCallback, useMemo } from "react";
import { Container, Loader } from "../../components";
import { useAuthContext } from "../../context/auth";
import { getEdificios } from "../../utils";
import "./styles.scss";

export const Inventario = () => {
  const [edificios, setEdificios] = useState();
  const [sortBy, setSortBy] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortedColumn, setSortedColumn] = useState(null);

  const { pais } = useAuthContext();

  useEffect(() => {
    const initialize = async () => {
      const newEdificios = await getEdificios({ pais });
      const sortedByEstado = [...newEdificios].sort((a, b) => {
        if (a.estado < b.estado) return -1;
        if (a.estado > b.estado) return 1;
        return 0;
      });
      const sortedByEstadoAndPantallas = sortedByEstado.sort((a, b) => {
        if (a.estado === b.estado) {
          return b.pantallas - a.pantallas;
        }
        return 0;
      });
      const sortedByEstadoPantallasAndEdificio = sortedByEstadoAndPantallas.sort((a, b) => {
        if (a.estado === b.estado && a.pantallas === b.pantallas) {
          return a.edificio.localeCompare(b.edificio);
        }
        return 0;
      });

      setEdificios(sortedByEstadoPantallasAndEdificio);
    };

    initialize();
  }, [pais]);

  const handleSort = (column) => {
    if (sortBy === column) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortBy(column);
      setSortOrder("asc");
      setSortedColumn(column);
    }
  };

  const sortedEdificios = useMemo(() => {
    if (edificios) {
      return [...edificios].sort((a, b) => {
        if (sortBy === "pantallas" || sortBy === "personas") {
          return sortOrder === "asc" ? a[sortBy] - b[sortBy] : b[sortBy] - a[sortBy];
        } else if (sortBy === "estado") {
          if (a.estado < b.estado) return sortOrder === "asc" ? -1 : 1;
          if (a.estado > b.estado) return sortOrder === "asc" ? 1 : -1;
        } else {
          const aValue = (a[sortBy] || "").toLowerCase();
          const bValue = (b[sortBy] || "").toLowerCase();
          return sortOrder === "asc" ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
        }
        return 0;
      });
    }
    return edificios;
  }, [edificios, sortBy, sortOrder]);

  const localString = useCallback(
    (pais) =>
      ({
        Chile: "es-CL",
        Perú: "es-PE",
        Uruguay: "es-UY",
      })[pais] || "es-UY",
    []
  );

  let a = 1;

  return (
    <Container className="Admin" header titulo="INVENTARIO DISPONIBLE">
      <div>
        <h6 className="texto">(PUEDE REORDENAR EL CONTENIDO SEGÚN SU ENCABEZADO)</h6>
        {sortedEdificios ? (
          <div className="table-responsive mx-auto" style={{ width: "75vw", textAlign: "center" }}>
            <table className="table table-striped table-hover table-bordered mt-4 table-sm">
              <thead className="table-dark text-center align-middle">
                <tr>
                  <th scope="col" className="col">
                    #
                  </th>
                  <th
                    scope="col"
                    className={`col ${sortedColumn === "edificio" ? "sorted" : ""}`}
                    onClick={() => handleSort("edificio")}
                  >
                    EDIFICIO
                    {sortedColumn === "edificio" && <span>{sortOrder === "asc" ? " ▲" : " ▼"}</span>}
                  </th>
                  <th
                    scope="col"
                    className={`col ${sortedColumn === "estado" ? "sorted" : ""}`}
                    onClick={() => handleSort("estado")}
                  >
                    STATUS
                    {sortedColumn === "estado" && <span>{sortOrder === "asc" ? " ▲" : " ▼"}</span>}
                  </th>
                  <th
                    scope="col"
                    className={`col ${sortedColumn === "ubicacion" ? "sorted" : ""}`}
                    onClick={() => handleSort("ubicacion")}
                  >
                    UBICACIÓN
                    {sortedColumn === "ubicacion" && <span>{sortOrder === "asc" ? " ▲" : " ▼"}</span>}
                  </th>
                  <th
                    scope="col"
                    className={`col ${sortedColumn === "direccion" ? "sorted" : ""}`}
                    onClick={() => handleSort("direccion")}
                  >
                    DIRECCIÓN
                    {sortedColumn === "direccion" && <span>{sortOrder === "asc" ? " ▲" : " ▼"}</span>}
                  </th>
                  <th
                    scope="col"
                    className={`col ${sortedColumn === "personas" ? "sorted" : ""}`}
                    onClick={() => handleSort("personas")}
                  >
                    AFORO
                    {sortedColumn === "personas" && <span>{sortOrder === "asc" ? " ▲" : " ▼"}</span>}
                  </th>
                  <th
                    scope="col"
                    className={`col ${sortedColumn === "pantallas" ? "sorted" : ""}`}
                    onClick={() => handleSort("pantallas")}
                  >
                    PANTALLAS
                    {sortedColumn === "pantallas" && <span>{sortOrder === "asc" ? " ▲" : " ▼"}</span>}
                  </th>
                </tr>
              </thead>

              <tbody className="table-striped text-center align-middle">
                {sortedEdificios.map(({ id, edificio, pantallas, ubicacion, direccion, personas, estado }) => {
                  const className =
                    estado === "OPERATIVO"
                      ? "estadoOperativo"
                      : estado === "PRÓXIMO"
                        ? "estadoProximo"
                        : "estadoStandby";
                  const statusStyle = {
                    backgroundColor:
                      estado === "OPERATIVO"
                        ? "green"
                        : estado === "PRÓXIMO"
                          ? "darkorange"
                          : estado === "STANDBY"
                            ? "red"
                            : "",
                    color: "white",
                  };
                  return (
                    <tr key={id}>
                      <td className="col">{a++}</td>
                      <td className="col">{edificio.toUpperCase()}</td>
                      <td className={`col ${className}`} style={statusStyle}>
                        {estado}
                      </td>
                      <td className="col">{ubicacion}</td>
                      <td className="col">{direccion}</td>
                      <td className="col">
                        {personas.toLocaleString(localString(pais), {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}
                      </td>
                      <td className="col">{pantallas}</td>
                    </tr>
                  );
                })}
              </tbody>
              <tfoot className="bottom">
                <tr className="table-danger text-center align-middle">
                  <td colSpan="5" className="col text-end">
                    TOTALES
                  </td>
                  <td className="col bottomtotals">
                    {sortedEdificios
                      .map((item) => item.personas)
                      .reduce((prev, curr) => prev + curr, 0)
                      .toLocaleString(localString(pais), {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })}
                  </td>
                  <td className="col bottomtotals">
                    {sortedEdificios
                      .map((item) => item.pantallas)
                      .reduce((prev, curr) => prev + curr, 0)
                      .toLocaleString(localString(pais), {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })}
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        ) : (
          <Loader />
        )}
      </div>
    </Container>
  );
};
