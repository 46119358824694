/* eslint-disable no-console */
import { useCallback, useEffect, useState } from "react";
import {
  XYPlot,
  VerticalBarSeries,
  XAxis,
  YAxis,
  HorizontalGridLines,
  LineSeries,
  DiscreteColorLegend,
} from "react-vis";
import { useAuthContext } from "../../context/auth";
import { getOportunidades } from "../../utils";
import "react-vis/dist/style.css";

export const GraficasVentas = () => {
  const [oportunidadesPorMes, setOportunidadesPorMes] = useState([]);
  const { pais } = useAuthContext();

  const objetivosMensuales = useCallback((pais) => {
    let objetivos = [];

    if (pais === "Chile") {
      objetivos = [
        { x: "ENE", y: 5000000 / 1000 },
        { x: "FEB", y: 7500000 / 1000 },
        { x: "MAR", y: 10000000 / 1000 },
        { x: "ABR", y: 15000000 / 1000 },
        { x: "MAY", y: 20000000 / 1000 },
        { x: "JUN", y: 30000000 / 1000 },
        { x: "JUL", y: 40000000 / 1000 },
        { x: "AGO", y: 60000000 / 1000 },
        { x: "SEP", y: 70000000 / 1000 },
        { x: "OCT", y: 80000000 / 1000 },
        { x: "NOV", y: 90000000 / 1000 },
        { x: "DIC", y: 100000000 / 1000 },
      ];
    } else if (pais === "Perú") {
      objetivos = [
        { x: "ENE", y: 90418 / 1000 },
        { x: "FEB", y: 137130 / 1000 },
        { x: "MAR", y: 141569 / 1000 },
        { x: "ABR", y: 128100 / 1000 },
        { x: "MAY", y: 165900 / 1000 },
        { x: "JUN", y: 185900 / 1000 },
        { x: "JUL", y: 185780 / 1000 },
        { x: "AGO", y: 215600 / 1000 },
        { x: "SEP", y: 237000 / 1000 },
        { x: "OCT", y: 221340 / 1000 },
        { x: "NOV", y: 226940 / 1000 },
        { x: "DIC", y: 230300 / 1000 },
      ];
    } else if (pais === "Uruguay") {
      objetivos = [
        { x: "ENE", y: 600000 / 1000 },
        { x: "FEB", y: 600000 / 1000 },
        { x: "MAR", y: 600000 / 1000 },
        { x: "ABR", y: 600000 / 1000 },
        { x: "MAY", y: 600000 / 1000 },
        { x: "JUN", y: 600000 / 1000 },
        { x: "JUL", y: 600000 / 1000 },
        { x: "AGO", y: 600000 / 1000 },
        { x: "SEP", y: 600000 / 1000 },
        { x: "OCT", y: 600000 / 1000 },
        { x: "NOV", y: 600000 / 1000 },
        { x: "DIC", y: 600000 / 1000 },
      ];
    }

    return objetivos;
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const nuevasOportunidades = await getOportunidades({ pais });
        const oportunidades100PorCiento = nuevasOportunidades.filter((oportunidad) => oportunidad.estatus === "100%");
        const oportunidades90PorCiento = nuevasOportunidades.filter((oportunidad) => oportunidad.estatus === "90%");
        const oportunidades75PorCiento = nuevasOportunidades.filter((oportunidad) => oportunidad.estatus === "75%");
        const oportunidadesAgrupadas = agruparOportunidadesPorMes(
          oportunidades100PorCiento,
          oportunidades90PorCiento,
          oportunidades75PorCiento
        );
        setOportunidadesPorMes(oportunidadesAgrupadas);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [pais]);

  const agruparOportunidadesPorMes = (oportunidades100, oportunidades90, oportunidades75) => {
    const oportunidadesAgrupadas = {};

    for (let mes = 0; mes < 12; mes++) {
      oportunidadesAgrupadas[mes] = { "100%": 0, "90%": 0, "75%": 0 };
    }

    oportunidades100.forEach((oportunidad) => {
      const mes = parseInt(oportunidad.mes) - 1;
      const volumen = oportunidad.importe;
      oportunidadesAgrupadas[mes]["100%"] += volumen;
    });

    oportunidades90.forEach((oportunidad) => {
      const mes = parseInt(oportunidad.mes) - 1;
      const volumen = oportunidad.importe;
      oportunidadesAgrupadas[mes]["90%"] += volumen;
    });

    oportunidades75.forEach((oportunidad) => {
      const mes = parseInt(oportunidad.mes) - 1;
      const volumen = oportunidad.importe;
      oportunidadesAgrupadas[mes]["75%"] += volumen;
    });

    return oportunidadesAgrupadas;
  };

  const convertirDatosParaGrafica100 = () => {
    if (!oportunidadesPorMes) return [];

    const nombresMeses = ["ENE", "FEB", "MAR", "ABR", "MAY", "JUN", "JUL", "AGO", "SEP", "OCT", "NOV", "DIC"];
    const datos = [];

    for (let mes = 0; mes < 12; mes++) {
      const oportunidades100 = oportunidadesPorMes[mes] ? oportunidadesPorMes[mes]["100%"] / 1000 || 0 : 0;

      datos.push({
        x: nombresMeses[mes],
        y: oportunidades100,
      });
    }

    return datos;
  };

  const convertirDatosParaGrafica90 = () => {
    const nombresMeses = ["ENE", "FEB", "MAR", "ABR", "MAY", "JUN", "JUL", "AGO", "SEP", "OCT", "NOV", "DIC"];
    const datos = [];

    for (let mes = 0; mes < 12; mes++) {
      const oportunidades90 = oportunidadesPorMes[mes] ? oportunidadesPorMes[mes]["90%"] / 1000 || 0 : 0;

      datos.push({
        x: nombresMeses[mes],
        y: oportunidades90,
      });
    }

    return datos;
  };

  const convertirDatosParaGrafica75 = () => {
    const nombresMeses = ["ENE", "FEB", "MAR", "ABR", "MAY", "JUN", "JUL", "AGO", "SEP", "OCT", "NOV", "DIC"];
    const datos = [];

    for (let mes = 0; mes < 12; mes++) {
      const oportunidades75 = oportunidadesPorMes[mes] ? oportunidadesPorMes[mes]["75%"] / 1000 || 0 : 0;

      datos.push({
        x: nombresMeses[mes],
        y: oportunidades75,
      });
    }

    return datos;
  };

  const maxOportunidades = Math.max(
    ...Object.values(oportunidadesPorMes).map((mes) => mes["100%"] + mes["90%"] + mes["75%"])
  );

  const maxReference = (maxOportunidades * 1.15) / 1000;

  const legendItems = [{ title: "OBJETIVO de VENTAS", color: "red" }];

  return (
    <div style={{ textAlign: "end" }}>
      <XYPlot xType="ordinal" width={1400} margin={{ left: 50 }} height={400} stackBy="y" yDomain={[0, maxReference]}>
        <XAxis style={{ marginRight: 0, fontSize: "10px" }} />
        <YAxis tickFormat={(v) => `${v}`} style={{ fontSize: "10px" }} />
        <VerticalBarSeries data={convertirDatosParaGrafica100()} color="#198754" />
        <VerticalBarSeries data={convertirDatosParaGrafica90()} color="#dc3545" />
        <VerticalBarSeries data={convertirDatosParaGrafica75()} color="#0d6efd" />
        <HorizontalGridLines />
        <LineSeries color="red" data={objetivosMensuales(pais)} />
        <DiscreteColorLegend items={legendItems} />
      </XYPlot>
    </div>
  );
};
