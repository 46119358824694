import toast from "react-hot-toast";
import { log, supabase } from ".";

export const getEdificiosOperativos = async ({ pais }) => {
  try {
    const data = await supabase.select("edificios", {
      filters: {
        pais,
        estado: "OPERATIVO",
      },
      orderBy: "pantallas",
      ascending: false,
    });

    if (data?.length === 0) {
      toast.error("No hay edificios cargados en la base de datos");
      throw new Error("No data found");
    }
    return data;
  } catch (error) {
    log.error("getEdificiosOperativos", error);
    throw error;
  }
};
