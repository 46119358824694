/* eslint-disable quotes */
/* eslint-disable no-case-declarations */
/* eslint-disable max-len */
/* eslint-disable indent */
/* eslint-disable no-console */
import React, { useEffect, useCallback, useState, useRef } from "react";
import { Dropdown } from "react-bootstrap";
import { useAuthContext } from "../../context/auth";
import { getCategorias, getOportunidades } from "../../utils";
import "./styles.scss";

export const VentasDetalleRegionReciente = () => {
  const [oportunidades, setOportunidades] = useState([]);
  const [categorias, setCategorias] = useState();
  const [inicio, setInicio] = useState();
  const [fin, setFin] = useState();
  const [orderBy, setOrderBy] = useState(null);
  const [orderDesc, setOrderDesc] = useState(false);
  const [filtroCliente, setFiltroCliente] = useState("");
  const [filtroMes, setFiltroMes] = useState([]);
  const [filtroEstatus, setFiltroEstatus] = useState("");
  const [filtroIntermediario, setFiltroIntermediario] = useState("");
  const [filtroCategoria, setFiltroCategoria] = useState("");
  const [filtroPaises, setFiltroPaises] = useState({
    URU: true,
    PER: true,
    CHI: true,
  });
  const [checkboxStates, setCheckboxStates] = useState({
    URU: true,
    PER: true,
    CHI: true,
  });

  const { pais } = useAuthContext();

  const localString = useCallback(
    (pais) =>
      ({
        Chile: "es-CL",
        Perú: "es-PE",
        Uruguay: "es-UY",
      })[pais] || "es-UY",
    []
  );

  const paisCodigo = useCallback(
    (pais) =>
      ({
        Chile: "CHI",
        Perú: "PER",
        Uruguay: "URU",
      })[pais] || "URU",
    []
  );

  const tiposDeCambio = {
    Uruguay: 40,
    Perú: 3.75,
    Chile: 950,
  };

  const convertirADolares = (importe, monedaOrigen) => {
    const importeDolares = importe / tiposDeCambio[monedaOrigen];
    return Math.round(importeDolares);
  };

  const mesEnLetras = useCallback((mes) => {
    const mesesEnLetras = ["ENE", "FEB", "MAR", "ABR", "MAY", "JUN", "JUL", "AGO", "SEP", "OCT", "NOV", "DIC"];
    return mesesEnLetras[parseInt(mes) - 1] || "";
  }, []);

  const handleMesChange = useCallback((e) => {
    const selectedMonths = Array.from(
      e.target.closest(".dropdown-menu").querySelectorAll("input[type=checkbox]:checked"),
      (checkbox) => checkbox.value
    );
    setFiltroMes(selectedMonths);
  }, []);

  const renderMesOptions = () =>
    ["ENE", "FEB", "MAR", "ABR", "MAY", "JUN", "JUL", "AGO", "SEP", "OCT", "NOV", "DIC"].map((mes, index) => (
      <div key={index} className="form-check">
        <input
          className="form-check-input"
          type="checkbox"
          id={`mes${index}`}
          value={index < 9 ? `0${index + 1}` : `${index + 1}`}
          checked={filtroMes.includes(index < 9 ? `0${index + 1}` : `${index + 1}`)}
          onChange={handleMesChange}
        />
        <label className="form-check-label" htmlFor={`mes${index}`}>
          {mes}
        </label>
      </div>
    ));

  const renderClientesOptions = () => {
    const clientesOrdenados = [...new Set(oportunidades.map((oportunidad) => oportunidad.cliente))].sort();
    return clientesOrdenados.map((cliente, index) => (
      <option key={index} value={cliente}>
        {cliente}
      </option>
    ));
  };

  const renderIntermediariosOptions = () => {
    const intermediariosOrdenados = [...new Set(oportunidades.map((oportunidad) => oportunidad.intermediario))].sort();
    return intermediariosOrdenados.map((intermediario, index) => (
      <option key={index} value={intermediario}>
        {intermediario}
      </option>
    ));
  };

  const renderEstatusOptions = () =>
    Object.entries(customStatusOrder).map(([estatus, _], index) => (
      <option key={index} value={estatus}>
        {estatus}
      </option>
    ));

  const handlePaisCheckboxChange = (pais) => {
    setFiltroPaises((prevState) => ({
      ...prevState,
      [pais]: !prevState[pais],
    }));
  };

  useEffect(() => {
    // Actualizar el estado de los checkboxes cuando cambie el estado de los países
    setCheckboxStates((prevState) => ({
      ...prevState,
      ...filtroPaises,
    }));
  }, [filtroPaises]);

  const renderPaisesOptions = () =>
    Object.keys(filtroPaises).map((pais) => (
      <Dropdown.Item key={pais}>
        <input type="checkbox" checked={checkboxStates[pais]} onChange={() => handlePaisCheckboxChange(pais)} /> {pais}
      </Dropdown.Item>
    ));

  useEffect(() => {
    const fetchData = async () => {
      try {
        const oportunidadesUruguay = await getOportunidades({ pais: "Uruguay" });
        const oportunidadesPeru = await getOportunidades({ pais: "Perú" });
        const oportunidadesChile = await getOportunidades({ pais: "Chile" });

        const allOportunidades = [...oportunidadesUruguay, ...oportunidadesPeru, ...oportunidadesChile];

        // Filtrar las oportunidades según la condición de 'updated_at'
        const oportunidadesFiltradas = allOportunidades.filter((oportunidad) => {
          const updatedDate = new Date(oportunidad.updated_at);
          const today = new Date();
          const daysDifference = (today - updatedDate) / (1000 * 60 * 60 * 24); // Convertir la diferencia a días
          return daysDifference <= 16; // Filtrar si la diferencia es menor o igual a 16 días
        });

        setOportunidades(oportunidadesFiltradas);
        setInicio(today);
        setFin(today - 16);

        // Obtener y establecer las categorías
        const newCategorias = await getCategorias();
        setCategorias(newCategorias);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const renderCategoriaOptions = () =>
    categorias
      ? categorias.map((categoria, index) => (
          <option key={index} value={categoria.nombre}>
            {categoria.nombre}
          </option>
        ))
      : null;

  const handleCategoriaChange = (e) => {
    setFiltroCategoria(e.target.value);
  };

  const oportunidadesFiltradas = oportunidades.filter((oportunidad) => {
    const clienteMatch = !filtroCliente || oportunidad.cliente.includes(filtroCliente);
    const mesMatch = filtroMes.length === 0 || filtroMes.includes(oportunidad.mes);
    const estatusMatch = !filtroEstatus || oportunidad.estatus === filtroEstatus;
    const intermediarioMatch = !filtroIntermediario || oportunidad.intermediario.includes(filtroIntermediario);
    const categoriaMatch = !filtroCategoria || oportunidad.categoria === filtroCategoria;
    const paisMatch = filtroPaises[paisCodigo(oportunidad.pais)];

    return (
      clienteMatch &&
      mesMatch &&
      estatusMatch &&
      intermediarioMatch &&
      categoriaMatch &&
      paisMatch &&
      !oportunidad.eliminada
    );
  });

  const customStatusOrder = {
    "100%": 0,
    "90%": 1,
    "75%": 2,
    "50%": 3,
  };

  const handleSort = (column) => {
    if (orderBy === column) {
      setOrderDesc(!orderDesc);
    } else {
      setOrderBy(column);
      setOrderDesc(false);
    }
  };

  const sortedOportunidades = oportunidadesFiltradas.sort((a, b) => {
    let comparacion = 0;
    switch (orderBy) {
      case "pais":
      case "cliente":
      case "mes":
      case "categoria":
      case "intermediario":
      case "ejecutivo":
        comparacion = a[orderBy].localeCompare(b[orderBy]);
        break;
      case "estatus":
        comparacion = customStatusOrder[b.estatus] - customStatusOrder[a.estatus];
        break;
      case "importe":
        const importeA = convertirADolares(a.importe, a.pais);
        const importeB = convertirADolares(b.importe, b.pais);
        comparacion = importeA - importeB;
        break;
      default:
        comparacion = 0;
    }

    if (orderDesc) {
      comparacion *= -1;
    }

    return comparacion;
  });

  const renderArrow = (column) => {
    if (orderBy === column) {
      return orderDesc ? "↓" : "↑";
    } else {
      return null;
    }
  };

  const mesButtonRef = useRef(null);

  useEffect(() => {
    if (mesButtonRef.current) {
      mesButtonRef.current.addEventListener("click", (e) => {
        e.stopPropagation();
        e.preventDefault();
        mesButtonRef.current.nextSibling.classList.toggle("show");
      });

      document.addEventListener("click", () => {
        if (mesButtonRef.current && mesButtonRef.current.nextSibling) {
          mesButtonRef.current.nextSibling.classList.remove("show");
        }
      });
    }
  }, []);

  useEffect(() => {
    const setInitialOrder = () => {
      setOrderBy("estatus");
      setOrderDesc(true);
    };

    setInitialOrder();

    return () => {};
  }, []);

  useEffect(() => {
    const today = new Date();

    const inicio = new Date(today);
    inicio.setDate(today.getDate() - 16);

    setInicio(inicio);
    setFin(today);
  }, []);

  const formattedStartDate = inicio ? inicio.toLocaleDateString() : "";
  const formattedEndDate = fin ? fin.toLocaleDateString() : "";

  return (
    <div>
      <div className="row justify-content-center mt-2 mb-4">
        <div>
          <div className="mb-4 d-flex justify-content-center" style={{ backgroundColor: "black", color: "white" }}>
            <div style={{ display: "inline-block" }}>
              {formattedStartDate} AL {formattedEndDate}
            </div>
          </div>
        </div>
        <div className="col-md-2">
          <select className="form-control" value={filtroCliente} onChange={(e) => setFiltroCliente(e.target.value)}>
            <option value="">Cliente</option>
            {renderClientesOptions()}
          </select>
        </div>
        <div className="col-md-2">
          <div className="form-control">
            <button
              ref={mesButtonRef}
              className="btn dropdown-item dropdown-toggle"
              type="button"
              id="dropdownMenuButton"
            >
              Mes/es
            </button>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              {renderMesOptions()}
            </div>
          </div>
        </div>
        <div className="col-md-2">
          <select className="form-control" value={filtroEstatus} onChange={(e) => setFiltroEstatus(e.target.value)}>
            <option value="">Status</option>
            {renderEstatusOptions()}
          </select>
        </div>
        <div className="col-md-2">
          <select className="form-control" value={filtroCategoria} onChange={handleCategoriaChange}>
            <option value="">Categoría</option>
            {renderCategoriaOptions()}
          </select>
        </div>
        <div className="col-md-2">
          <select
            className="form-control"
            value={filtroIntermediario}
            onChange={(e) => setFiltroIntermediario(e.target.value)}
          >
            <option value="">Intermediario</option>
            {renderIntermediariosOptions()}
          </select>
        </div>
        <div className="col-md-2">
          <Dropdown>
            <Dropdown.Toggle variant="white border" id="dropdown-basic" className="form-control text-start">
              País/es
            </Dropdown.Toggle>
            <Dropdown.Menu>{renderPaisesOptions()}</Dropdown.Menu>
          </Dropdown>
        </div>
      </div>

      <div>
        <table className="table table-striped table-hover table-bordered table-sm" style={{ width: "100%" }}>
          <thead className="table-dark text-center align-middle">
            <tr>
              <th onClick={() => handleSort("pais")}>PAÍS {renderArrow("pais")}</th>
              <th onClick={() => handleSort("cliente")}>CLIENTE {renderArrow("cliente")}</th>
              <th onClick={() => handleSort("mes")}>MES {renderArrow("mes")}</th>
              <th onClick={() => handleSort("importe")}>TICKET {renderArrow("importe")}</th>
              <th onClick={() => handleSort("estatus")}>STATUS {renderArrow("estatus")}</th>
              <th onClick={() => handleSort("categoria")}>CATEGORÍA {renderArrow("categoria")}</th>
              <th onClick={() => handleSort("intermediario")}>INTERMEDIARIO {renderArrow("intermediario")}</th>
              <th onClick={() => handleSort("ejecutivo")}>EJECUTIVO {renderArrow("ejecutivo")}</th>
            </tr>
          </thead>

          <tbody className="table-striped">
            {sortedOportunidades.length === 0 ? (
              <tr>
                <td colSpan="13" className="text-center">
                  No hay ventas ni oportunidades según los filtros seleccionados :(
                </td>
              </tr>
            ) : (
              sortedOportunidades.map((oportunidad) => (
                <tr key={oportunidad.id}>
                  <td className="text-center col-1 align-middle">{paisCodigo(oportunidad.pais)}</td>
                  <td className="text-start col-2 align-middle">{oportunidad.cliente}</td>
                  <td className="text-center col-1 align-middle">{mesEnLetras(oportunidad.mes)}</td>
                  <td className="text-end col-1 align-end">
                    US$ {convertirADolares(oportunidad.importe, oportunidad.pais).toLocaleString(localString(pais))}
                  </td>
                  <td
                    className={`text-center col-1 align-middle ${
                      oportunidad.estatus === "100%"
                        ? "bg-success text-white"
                        : oportunidad.estatus === "90%"
                          ? "bg-danger text-white"
                          : oportunidad.estatus === "75%"
                            ? "bg-primary text-white"
                            : oportunidad.estatus === "50%"
                              ? "bg-warning text-dark"
                              : ""
                    }`}
                  >
                    {oportunidad.estatus}
                  </td>
                  <td className="text-center col-2 align-middle">{oportunidad.categoria}</td>
                  <td className="text-center col-2 align-middle">{oportunidad.intermediario}</td>
                  <td className="text-center col-1 align-middle">{oportunidad.ejecutivo}</td>
                </tr>
              ))
            )}
          </tbody>
          <tfoot>
            <tr className="table-danger text-center align-middle"> </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
};
