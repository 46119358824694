import { createContext, useState, useEffect, useContext, useCallback } from "react";
import { getCurrentUser, getSession, log, supabase } from "../utils";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [apellido, setApellido] = useState(null);
  const [correo, setCorreo] = useState(null);
  const [id, setId] = useState(null);
  const [nombre, setNombre] = useState(null);
  const [pais, setPais] = useState(null);
  const [rol, setRol] = useState(null);
  const [habilitado, setHabilitado] = useState(null);
  const [session, setSession] = useState();

  const onSession = useCallback(async (session) => {
    try {
      if (session) {
        const user = await getCurrentUser();

        if (user) {
          setApellido(user.user_metadata.apellido);
          setCorreo(user.email);
          setHabilitado(user.permissions.habilitado);
          setId(user.id);
          setNombre(user.user_metadata.nombre);
          setRol(user.permissions.rol);
          setSession(session);
        } else throw new Error("no id");
      } else setSession(null);
    } catch (error) {
      log.error("getUserById", error);
      setSession(null);
    }
  }, []);

  useEffect(() => {
    const getPais = (hostname) => {
      if (hostname.includes("visiona.pe")) return "Perú";
      else if (hostname.includes("wecast.cl")) return "Chile";
      else if (hostname.includes("screenmedia.com.uy")) return "Uruguay";
      else return "Perú";
    };

    const setupPais = () => {
      const newPais = getPais(window.location.hostname);
      setPais(newPais);
    };

    const setupSession = async () => {
      const session = await getSession();
      if (session !== null) onSession(session);

      supabase.onAuthStateChange((session) => onSession(session));
    };

    setupPais();
    setupSession();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthContext.Provider
      value={{
        apellido,
        correo,
        habilitado,
        id,
        nombre,
        pais,
        rol,
        session,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => {
  const context = useContext(AuthContext);

  if (!context) throw new Error("useAuthContext must be used within a AuthProvider");

  return context;
};
