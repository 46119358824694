/* eslint-disable no-console */
import { useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from "reactstrap";
import { Container, Loader } from "..";
import { useAuthContext } from "../../context/auth";
import { log, getOportunidades, updateOportunidad } from "../../utils";
import "./styles.scss";

export const OportunidadesFactura = () => {
  const { oportunidadId } = useParams();

  const [oportunidadIdentificada, setOportunidadIdentificada] = useState();
  const [showModalConfirmacion, setShowModalConfirmacion] = useState(false);
  const [temporalDataForm, setTemporalDataForm] = useState();
  const [loading, setLoading] = useState(false);

  const { pais } = useAuthContext();

  const methods = useForm();

  const { handleSubmit, register, setValue } = methods;

  const onSubmit = (data) => {
    const facturarOportunidad = {
      facturanumero: data.facturanumero,
      facturafecha: data.facturafecha,
      facturafechacobro: data.facturafechacobro,
    };

    setTemporalDataForm(facturarOportunidad);
    setShowModalConfirmacion(true); // Muestra el modal de confirmación
  };

  const saveButtonHandle = async () => {
    try {
      setLoading(true);
      setShowModalConfirmacion(false);
      await updateOportunidad({ id: oportunidadId, oportunidad: temporalDataForm });
      toast.success("La oportunidad se actualizó correctamente");
      setLoading(false);
    } catch (error) {
      if (error.code === "23505") setError("nombre", { type: "custom", message: "La oportunidad ya existe" });
      toast.error("Hubo un error al actualizar la oportunidad, prueba de nuevo.");
      log.error("saveButtonHandle", error);
      setLoading(false);
      setShowModalConfirmacion(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const allOportunidades = await getOportunidades({ pais });
        const oportunidadAEditar = allOportunidades.find((op) => op.id === Number(oportunidadId));

        if (oportunidadAEditar) {
          setOportunidadIdentificada(oportunidadAEditar);
          setValue("facturanumero", oportunidadAEditar.facturanumero);
          setValue("facturafecha", oportunidadAEditar.facturafecha);
          setValue("facturafechacobro", oportunidadAEditar.facturafechacobro);
        } else {
          console.warn("No se encontró la oportunidad con ID:", oportunidadId);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (oportunidadId && !oportunidadIdentificada) {
      fetchData();
    }
  }, [pais, oportunidadId, setValue, oportunidadIdentificada]);

  return (
    <Container
      className="Container"
      header
      titulo={`FACTURAR OPORTUNIDAD #${oportunidadId}: ${oportunidadIdentificada ? oportunidadIdentificada.cliente : ""}`}
    >
      {oportunidadIdentificada ? (
        <FormProvider {...methods}>
          <form className="row g-3 mt-2" onSubmit={handleSubmit(onSubmit)}>
            <div className="col-3 text-start fw-bold">
              <label htmlFor="facturanumero" className="form-label">
                FACTURA ID
              </label>
              <input
                className="form-control"
                id="facturanumero"
                type="text"
                {...register("facturanumero", { required: true })}
              />
            </div>

            <div className="col-3 text-start fw-bold">
              <label htmlFor="facturafecha" className="form-label">
                FACTURA FECHA
              </label>
              <input
                className="form-control"
                id="facturafecha"
                type="date"
                {...register("facturafecha", { required: true })}
              />
            </div>

            <div className="col-3 text-start fw-bold">
              <label htmlFor="facturafechacobro" className="form-label">
                FACTURA COBRO
              </label>
              <input
                className="form-control"
                id="facturafechacobro"
                type="date"
                {...register("facturafechacobro", { required: false })}
              />
            </div>

            <div className="col-12 justify-content-center">
              <button className="btn btn-primary mb-5" type="submit" disabled={loading || showModalConfirmacion}>
                {!loading && !showModalConfirmacion ? (
                  "ACTUALIZAR"
                ) : (
                  <>
                    <Spinner size="sm">GUARDANDO...</Spinner>
                    <span> GUARDANDO</span>
                  </>
                )}
              </button>
            </div>
          </form>

          <Modal isOpen={showModalConfirmacion} toggle={() => setShowModalConfirmacion(false)}>
            <ModalHeader>¿Está seguro?</ModalHeader>
            <ModalBody>Se está por actualizar la oportunidad</ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={() => setShowModalConfirmacion(false)}>
                Cancelar
              </Button>
              <Button color="primary" onClick={saveButtonHandle}>
                Guardar
              </Button>
            </ModalFooter>
          </Modal>
        </FormProvider>
      ) : (
        <Loader />
      )}
    </Container>
  );
};
