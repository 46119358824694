import { log, supabase } from ".";

export const logout = async () => {
  try {
    const { success } = await supabase.logout();
    log.debug("logout", success && "success");
  } catch (error) {
    log.error("logout", error);
  }
};
