/* eslint-disable unicorn/consistent-destructuring */
/* eslint-disable no-console */
import { useEffect, useState } from "react";
import { XYPlot, YAxis, HorizontalBarSeries, XAxis, VerticalGridLines } from "react-vis";
import { useAuthContext } from "../../context/auth";
import { getOportunidades, getCategorias } from "../../utils";
import "./styles.scss";

export const GraficasCategorias = () => {
  const [oportunidadesPorCategorias, setOportunidadesPorCategorias] = useState([]);
  const [categorias, setCategorias] = useState([]);
  const { pais } = useAuthContext();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const nuevasOportunidades = await getOportunidades({ pais });
        const oportunidadesFiltradas = nuevasOportunidades.filter(
          (oportunidad) =>
            oportunidad.estatus === "100%" || oportunidad.estatus === "90%" || oportunidad.estatus === "75%"
        );
        const oportunidadesAgrupadas = agruparOportunidadesPorCategoria(oportunidadesFiltradas);
        setOportunidadesPorCategorias(oportunidadesAgrupadas);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [pais]);

  useEffect(() => {
    const fetchCategorias = async () => {
      try {
        const categoriasObtenidas = await getCategorias();
        setCategorias(categoriasObtenidas);
      } catch (error) {
        console.error("Error fetching categorias:", error);
      }
    };

    fetchCategorias();
  }, []);

  const agruparOportunidadesPorCategoria = (oportunidades) => {
    const oportunidadesAgrupadas = {};

    oportunidades.forEach((oportunidad) => {
      const { categoria, importe } = oportunidad;
      if (!oportunidadesAgrupadas[categoria]) {
        oportunidadesAgrupadas[categoria] = { "100%": 0, "90%": 0, "75%": 0 };
      }
      oportunidadesAgrupadas[categoria][oportunidad.estatus] += importe;
    });

    return oportunidadesAgrupadas;
  };

  const maxOportunidades = Math.max(
    ...Object.values(oportunidadesPorCategorias).map(
      (categoria) => categoria["100%"] + categoria["90%"] + categoria["75%"]
    )
  );

  const maxReference = (maxOportunidades * 1.15) / 1000;

  const convertirDatosParaGrafica100 = () => {
    const datos = [];

    const categoriasInvertidas = [...categorias].reverse();

    categoriasInvertidas.forEach((categoria) => {
      datos.push({
        y: categoria.nombre,
        x: oportunidadesPorCategorias[categoria.nombre]?.["100%"] / 1000 || 0,
      });
    });

    return datos;
  };

  const convertirDatosParaGrafica90 = () => {
    const datos = [];

    const categoriasInvertidas = [...categorias].reverse();

    categoriasInvertidas.forEach((categoria) => {
      datos.push({
        y: categoria.nombre,
        x: oportunidadesPorCategorias[categoria.nombre]?.["90%"] / 1000 || 0,
      });
    });

    return datos;
  };

  const convertirDatosParaGrafica75 = () => {
    const datos = [];

    const categoriasInvertidas = [...categorias].reverse();

    categoriasInvertidas.forEach((categoria) => {
      datos.push({
        y: categoria.nombre,
        x: oportunidadesPorCategorias[categoria.nombre]?.["75%"] / 1000 || 0,
      });
    });

    return datos;
  };

  return (
    <div className="grafica-categorias">
      <XYPlot width={1000} height={1400} stackBy="x" margin={{ left: 250 }} yType="ordinal" xDomain={[0, maxReference]}>
        <YAxis />
        <XAxis />
        <VerticalGridLines />
        <HorizontalBarSeries data={convertirDatosParaGrafica100()} color="#198754" />
        <HorizontalBarSeries data={convertirDatosParaGrafica90()} color="#dc3545" />
        <HorizontalBarSeries data={convertirDatosParaGrafica75()} color="#0d6efd" />
      </XYPlot>
    </div>
  );
};
