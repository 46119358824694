/* eslint-disable indent */
/* eslint-disable no-console */
import React, { useEffect, useCallback, useState, useRef } from "react";
import toast from "react-hot-toast";
import { FaEdit, FaTrash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useAuthContext } from "../../context/auth";
import { getCategorias, getOportunidades, updateOportunidad } from "../../utils";
import "./styles.scss";

export const VentasDetalle = () => {
  const [oportunidades, setOportunidades] = useState([]);
  const [categorias, setCategorias] = useState();
  const [loading, setLoading] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [deletedOpportunity, setDeletedOpportunity] = useState(null);
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const [orderBy, setOrderBy] = useState(null);
  const [orderDesc, setOrderDesc] = useState(false);
  const [filtroCliente, setFiltroCliente] = useState("");
  const [filtroMes, setFiltroMes] = useState([]);
  const [filtroEstatus, setFiltroEstatus] = useState("");
  const [filtroIntermediario, setFiltroIntermediario] = useState("");
  const [filtroCategoria, setFiltroCategoria] = useState("");

  const { pais } = useAuthContext();

  const navigate = useNavigate();

  const goToOportunidad = useCallback((id) => navigate(`/admin/oportunidad/${id}`), [navigate]);

  const localString = useCallback(
    (pais) =>
      ({
        Chile: "es-CL",
        Perú: "es-PE",
        Uruguay: "es-UY",
      })[pais] || "es-UY",
    []
  );

  const moneda = useCallback(
    (pais) =>
      ({
        Chile: "$",
        Perú: "S/",
        Uruguay: "$",
      })[pais] || "$",
    []
  );

  const decimales = useCallback(
    (pais) =>
      ({
        Chile: 0,
        Perú: 2,
        Uruguay: 0,
      })[pais] || 0,
    []
  );

  const mesEnLetras = useCallback((mes) => {
    const mesesEnLetras = ["ENE", "FEB", "MAR", "ABR", "MAY", "JUN", "JUL", "AGO", "SEP", "OCT", "NOV", "DIC"];
    return mesesEnLetras[parseInt(mes) - 1] || "";
  }, []);

  const handleMesChange = useCallback((e) => {
    const selectedMonths = Array.from(
      e.target.closest(".dropdown-menu").querySelectorAll("input[type=checkbox]:checked"),
      (checkbox) => checkbox.value
    );
    setFiltroMes(selectedMonths);
  }, []);

  const renderMesOptions = () =>
    ["ENE", "FEB", "MAR", "ABR", "MAY", "JUN", "JUL", "AGO", "SEP", "OCT", "NOV", "DIC"].map((mes, index) => (
      <div key={index} className="form-check">
        <input
          className="form-check-input"
          type="checkbox"
          id={`mes${index}`}
          value={index < 9 ? `0${index + 1}` : `${index + 1}`}
          checked={filtroMes.includes(index < 9 ? `0${index + 1}` : `${index + 1}`)}
          onChange={handleMesChange}
        />
        <label className="form-check-label" htmlFor={`mes${index}`}>
          {mes}
        </label>
      </div>
    ));

  const renderClientesOptions = () => {
    const clientesOrdenados = [...new Set(oportunidades.map((oportunidad) => oportunidad.cliente))].sort();
    return clientesOrdenados.map((cliente, index) => (
      <option key={index} value={cliente}>
        {cliente}
      </option>
    ));
  };

  const renderIntermediariosOptions = () => {
    const intermediariosOrdenados = [...new Set(oportunidades.map((oportunidad) => oportunidad.intermediario))].sort();
    return intermediariosOrdenados.map((intermediario, index) => (
      <option key={index} value={intermediario}>
        {intermediario}
      </option>
    ));
  };

  const renderEstatusOptions = () =>
    Object.entries(customStatusOrder).map(([estatus, _], index) => (
      <option key={index} value={estatus}>
        {estatus}
      </option>
    ));

  const handleDelete = async (id) => {
    try {
      setLoading(true);
      await updateOportunidad({ id, oportunidad: { eliminada: true } });
      const updatedOportunidades = oportunidades.map((oportunidad) =>
        oportunidad.id === id ? { ...oportunidad, eliminado: true } : oportunidad
      );
      setOportunidades(updatedOportunidades);
      toast.success("La oportunidad se eliminó correctamente");
      setShowConfirmationModal(false);
      setDeletedOpportunity(null);
      setTimeout(() => {}, 1000);
      setShouldRefresh(true);
    } catch (error) {
      console.error("Error deleting oportunidad:", error);
      toast.error("Hubo un error al eliminar la oportunidad, prueba de nuevo.");
    } finally {
      setLoading(false);
    }
  };

  const handleConfirmationModal = (oportunidad) => {
    setShowConfirmationModal(true);
    setDeletedOpportunity(oportunidad);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const newOportunidades = await getOportunidades({ pais });
        // Filtrar oportunidades del año 2024
        const oportunidades2024 = newOportunidades.filter((oportunidad) => {
          const fechaCreacion = new Date(oportunidad.created_at);
          const year = fechaCreacion.getFullYear();
          return year === 2024;
        });
        setOportunidades(oportunidades2024);

        const newCategorias = await getCategorias();
        setCategorias(newCategorias);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [pais]);

  const renderCategoriaOptions = () =>
    categorias
      ? categorias.map((categoria, index) => (
          <option key={index} value={categoria.nombre}>
            {categoria.nombre}
          </option>
        ))
      : null;

  const handleCategoriaChange = (e) => {
    setFiltroCategoria(e.target.value);
  };

  const oportunidadesFiltradas = oportunidades.filter((oportunidad) => {
    const clienteMatch = !filtroCliente || oportunidad.cliente.includes(filtroCliente);
    const mesMatch = filtroMes.length === 0 || filtroMes.includes(oportunidad.mes);
    const estatusMatch = !filtroEstatus || oportunidad.estatus === filtroEstatus;
    const intermediarioMatch = !filtroIntermediario || oportunidad.intermediario.includes(filtroIntermediario);
    const categoriaMatch = !filtroCategoria || oportunidad.categoria === filtroCategoria;

    return clienteMatch && mesMatch && estatusMatch && intermediarioMatch && categoriaMatch && !oportunidad.eliminada;
  });

  useEffect(() => {
    if (shouldRefresh) {
      window.location.reload();
    }
  }, [shouldRefresh]);

  const customStatusOrder = {
    "100%": 0,
    "90%": 1,
    "75%": 2,
    "50%": 3,
    "0%": 4,
  };

  const handleSort = (column) => {
    if (orderBy === column) {
      setOrderDesc(!orderDesc);
    } else {
      setOrderBy(column);
      setOrderDesc(false);
    }
  };

  const sortedOportunidades = oportunidadesFiltradas.sort((a, b) => {
    // Priorizar oportunidades con facturanumero
    if (a.facturanumero && !b.facturanumero) {
      return -1;
    } else if (!a.facturanumero && b.facturanumero) {
      return 1;
    } else {
      // Ordenar por mes (ascendente)
      if (a.mes !== b.mes) {
        return a.mes - b.mes;
      }

      // Ordenar por estatus (según customStatusOrder)
      const statusOrderA = customStatusOrder[a.estatus];
      const statusOrderB = customStatusOrder[b.estatus];
      if (statusOrderA !== statusOrderB) {
        return statusOrderA - statusOrderB; // Cambio aquí
      }

      // Ordenar por importe (descendente)
      return b.importe - a.importe;
    }
  });

  const renderArrow = (column) => {
    if (orderBy === column) {
      return orderDesc ? "↓" : "↑";
    } else {
      return null;
    }
  };

  const mesButtonRef = useRef(null);

  useEffect(() => {
    if (mesButtonRef.current) {
      mesButtonRef.current.addEventListener("click", (e) => {
        e.stopPropagation();
        e.preventDefault();
        mesButtonRef.current.nextSibling.classList.toggle("show");
      });

      document.addEventListener("click", () => {
        if (mesButtonRef.current && mesButtonRef.current.nextSibling) {
          mesButtonRef.current.nextSibling.classList.remove("show");
        }
      });
    }
  }, []);

  return (
    <div>
      <div className="row justify-content-center mt-2 mb-4">
        <div className="col-md-2">
          <select className="form-control" value={filtroCliente} onChange={(e) => setFiltroCliente(e.target.value)}>
            <option value="">Filtrar por cliente</option>
            {renderClientesOptions()}
          </select>
        </div>
        <div className="col-md-2">
          <div className="form-control">
            <button
              ref={mesButtonRef}
              className="btn dropdown-item dropdown-toggle"
              type="button"
              id="dropdownMenuButton"
            >
              Filtrar por mes/meses
            </button>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              {renderMesOptions()}
            </div>
          </div>
        </div>
        <div className="col-md-2">
          <select className="form-control" value={filtroEstatus} onChange={(e) => setFiltroEstatus(e.target.value)}>
            <option value="">Filtrar por status</option>
            {renderEstatusOptions()}
          </select>
        </div>
        <div className="col-md-2">
          <select className="form-control" value={filtroCategoria} onChange={handleCategoriaChange}>
            <option value="">Filtrar por categoría</option>
            {renderCategoriaOptions()}
          </select>
        </div>
        <div className="col-md-2">
          <select
            className="form-control"
            value={filtroIntermediario}
            onChange={(e) => setFiltroIntermediario(e.target.value)}
          >
            <option value="">Filtrar por intermediario</option>
            {renderIntermediariosOptions()}
          </select>
        </div>
      </div>

      <div>
        {loading}
        <table className="table table-striped table-hover table-bordered table-sm" style={{ width: "100%" }}>
          <thead className="table-dark text-center align-middle">
            <tr>
              <th onClick={() => handleSort("id")}>ID {renderArrow("id")}</th>
              <th onClick={() => handleSort("cliente")}>CLIENTE {renderArrow("cliente")}</th>
              <th onClick={() => handleSort("mes")}>MES {renderArrow("mes")}</th>
              <th onClick={() => handleSort("importe")}>TICKET {renderArrow("importe")}</th>
              <th onClick={() => handleSort("estatus")}>STATUS {renderArrow("estatus")}</th>
              <th onClick={() => handleSort("formatopauta")}>FORMATO {renderArrow("formatopauta")}</th>
              <th onClick={() => handleSort("periodopauta")}>PERÍODO {renderArrow("periodopauta")}</th>
              <th onClick={() => handleSort("edificiostotales")}>EDIFICIOS {renderArrow("edificiostotales")}</th>
              <th onClick={() => handleSort("pantallastotales")}>PANTALLAS {renderArrow("pantallastotales")}</th>
              <th onClick={() => handleSort("importePorPantalla")}>
                {moneda(pais)} POR PANTALLA {renderArrow("importePorPantalla")}
              </th>
              <th onClick={() => handleSort("intermediario")}>INTERMEDIARIO {renderArrow("intermediario")}</th>
              <th onClick={() => handleSort("ejecutivo")}>EJECUTIVO {renderArrow("ejecutivo")}</th>
              <th colSpan="2">EDITAR</th>
            </tr>
          </thead>

          <tbody className="table-striped">
            {sortedOportunidades.length === 0 ? (
              <tr>
                <td colSpan="13" className="text-center">
                  No hay ventas ni oportunidades según los filtros seleccionados :(
                </td>
              </tr>
            ) : (
              sortedOportunidades.map((oportunidad) => (
                <tr key={oportunidad.id}>
                  <td className="text-center col-1 align-middle">{oportunidad.id}</td>
                  <td className="text-start col-3 align-middle">{oportunidad.cliente}</td>
                  <td className="text-center col-1 align-middle">{mesEnLetras(oportunidad.mes)}</td>
                  <td className="text-end col-2 align-middle">
                    {moneda(pais)}
                    {oportunidad.importe.toLocaleString(localString(pais), {
                      maximumFractionDigits: decimales(pais),
                      minimumFractionDigits: decimales(pais),
                    })}
                  </td>
                  <td
                    className={`text-center col-1 align-middle ${
                      oportunidad.estatus === "100%"
                        ? "bg-success text-white"
                        : oportunidad.estatus === "90%"
                          ? "bg-danger text-white"
                          : oportunidad.estatus === "75%"
                            ? "bg-primary text-white"
                            : oportunidad.estatus === "50%"
                              ? "bg-warning text-dark"
                              : oportunidad.estatus === "0%"
                                ? "bg-black text-white"
                                : ""
                    }`}
                  >
                    {oportunidad.estatus}
                  </td>
                  <td className="text-center col-1 align-middle">{oportunidad.formatopauta}</td>
                  <td className="text-center col-1 align-middle">{oportunidad.periodopauta}</td>
                  <td className="text-center col-1 align-middle">{oportunidad.edificiostotales}</td>
                  <td className="text-center col-1 align-middle">{oportunidad.pantallastotales}</td>
                  <td className="text-center col-1 align-middle">
                    {`${moneda(pais)} ${(oportunidad.importe / oportunidad.pantallastotales).toLocaleString(
                      localString(pais),
                      {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      }
                    )}`}
                  </td>
                  <td className="text-center col-2 align-middle">{oportunidad.intermediario}</td>
                  <td className="text-center col-1 align-middle">{oportunidad.ejecutivo}</td>
                  <td className="text-center col-1 align-middle">
                    {oportunidad.facturanumero ? "-" : <FaEdit onClick={() => goToOportunidad(oportunidad.id)} />}
                  </td>
                  <td className="text-center col-1 align-middle">
                    {oportunidad.facturanumero ? "-" : <FaTrash onClick={() => handleConfirmationModal(oportunidad)} />}
                  </td>
                </tr>
              ))
            )}
          </tbody>
          <tfoot>
            <tr className="table-danger text-center align-middle"> </tr>
          </tfoot>
        </table>
      </div>

      <Modal isOpen={showConfirmationModal} toggle={() => setShowConfirmationModal(false)}>
        <ModalHeader>Confirmación</ModalHeader>
        <ModalBody>¿Está seguro de eliminar esta oportunidad?</ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setShowConfirmationModal(false)}>
            Cancelar
          </Button>
          <Button color="primary" onClick={() => handleDelete(deletedOpportunity.id)}>
            Eliminar
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};
